import * as OnDemandLiveRegion from 'on-demand-live-region';
import A11yDialog from 'a11y-dialog';
import Handlebars from 'handlebars';
import { Product } from '/src/site/base/product';
import { addObjectToElementDataLayer, initializeDatalayerByContext, DATALAYER_ATTRIBUTE_NAME } from '/src/site/utilities/datalayer-custom';

const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

function ProductSeriesCompareFlyout(element) {
    /**
     * Create the Constructor object
     * @param {Node} el         The node
     * @param {Object} options  Options and settings
     */
    this.$element = element;
    const compareInstance = this;

    // The default settings for the module.
    this.settings = {
        comparisonCount: 0,
        compareProductsDatalayer: [],
        productJsonSlot1: null,
        productJsonSlot2: null,
        parent: '.cmp-product-series-list',
        compareButton: '.js-product-series-compare-trigger',
        mobileExpandTrigger: '.compare-flyout__header-trigger',
        mobileExpandTarget: '.compare-flyout__body',
        amountIndicator: '.compare-flyout__amount',
        desktopmediaQuery: window.matchMedia('(min-width:1195px)')
    };

    // Cast parent as DOM node
    this.settings.parent = element.closest(compareInstance.settings.parent);
    this.settings.compareButton = element.querySelector(compareInstance.settings.compareButton);
    this.settings.mobileExpandTrigger = element.querySelector(compareInstance.settings.mobileExpandTrigger);
    this.settings.amountIndicator = element.querySelector(compareInstance.settings.amountIndicator);
    this.settings.mobileExpandTarget = element.querySelector(compareInstance.settings.mobileExpandTarget);

    this.settings.liveRegion = new OnDemandLiveRegion({
        level: 'assertive',
        delay: 300
    });

    this.settings.desktopmediaQuery.onchange = (event) => {
        if (event.matches) {
            /* On desktop = collapse the mobile trigger */
            compareInstance.contractCompareFlyout();
        } else {
            compareInstance.compensateSticky();
        }
    };

    compareInstance.handlePageSizeChange(compareInstance, this.settings.desktopmediaQuery);

    element.addEventListener('productSeriesComparisonChange', (event) => {
        switch (event.detail.action) {
        case 'add':
            const emptyProductSlot = compareInstance.checkProductSlots();
            compareInstance.updateSlot(emptyProductSlot, event.detail.productTitle, event.detail.productThumb, event.detail.productJson, event.detail.productLink, event.detail.trigger);
            break;
        case 'remove':
            const trigger = compareInstance.settings.parent.querySelector('input[data-product-series-id-compare="' + event.detail.product + '-compare"]');
            let slotToEmpty = compareInstance.$element.querySelector('.compare-flyout__slot[data-product="' + event.detail.productJson + '"]').dataset.slot;
            compareInstance.clearSlot(slotToEmpty, trigger, event.detail.productTitle);
            break;
        }
    });

    element.addEventListener('compareFlyoutClosed', () => {
        if (!compareInstance.settings.desktopmediaQuery.matches) {
            compareInstance.contractCompareFlyout();
        }
    }, false);

    this.settings.mobileExpandTrigger.addEventListener('click', (event) => {
        const compareFlyoutExpandedState = event.target.getAttribute('aria-expanded') !== 'false';
        if (!compareFlyoutExpandedState) {
            compareInstance.expandCompareFlyout();
        } else {
            compareInstance.contractCompareFlyout();
        }
    }, false);

    document.addEventListener('click', (event) => {
        if (event.target.matches('.compare-flyout__slot-remove')) {
            const checkbox = document.getElementById(event.target.dataset.target);
            const mouseEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            checkbox.dispatchEvent(mouseEvent);
        }

        if (event.target.matches('.js-product-series-compare-trigger')) {
            compareInstance.openCompareModal(event.target, compareInstance.settings.productJsonSlot1, compareInstance.settings.productJsonSlot2);
        }
    }, false);
}

// Enable compare Button Method
ProductSeriesCompareFlyout.prototype.enableCompareButton = function () {
    const productsDatalayer = {'products': this.settings.compareProductsDatalayer};
    addObjectToElementDataLayer(this.settings.compareButton, productsDatalayer, DATALAYER_ATTRIBUTE_NAME, true);
    initializeDatalayerByContext(this.settings.compareButton.parentNode, true);
    this.settings.compareButton.removeAttribute('disabled');
};

// Disable compare Button Method
ProductSeriesCompareFlyout.prototype.disableCompareButton = function () {
    this.settings.compareButton.setAttribute('disabled', 'true');
};

// Disable compare checkboxes
ProductSeriesCompareFlyout.prototype.disableCheckboxes = function () {
    const uncheckedCheckboxes = this.settings.parent.querySelectorAll('input[type=checkbox]:not(:checked)');
    [...uncheckedCheckboxes].forEach(uncheckedCheckbox => {
        uncheckedCheckbox.setAttribute('disabled', true);
    });
};

// Enable compare Checkboxes
ProductSeriesCompareFlyout.prototype.enableCheckboxes = function () {
    const disabledCheckboxes = this.settings.parent.querySelectorAll('input[type=checkbox][disabled]');
    [...disabledCheckboxes].forEach(disabledCheckbox => {
        disabledCheckbox.removeAttribute('disabled');
    });
};

// Show compare Flyout
ProductSeriesCompareFlyout.prototype.showCompareFlyout = function () {
    this.$element.setAttribute('data-is-active', true);
    this.compensateSticky();
};

// Hide compare Flyout
ProductSeriesCompareFlyout.prototype.hideCompareFlyout = function () {
    this.$element.setAttribute('data-closing', true);

    let closeEvent = new CustomEvent('compareFlyoutClosed', {
        bubbles: true,
        cancelable: false,
        composed: true,
    });

    const animationCallback = (event) => {
        event.target.removeAttribute('data-closing');
        event.target.removeAttribute('data-is-active');
        event.target.dispatchEvent(closeEvent);
        event.target.removeEventListener('animationend', animationCallback, false);
        document.body.style.setProperty('padding-bottom', '');
    };

    this.$element.addEventListener('animationend', animationCallback, false);
};

// Enable compare Checkboxes Method
ProductSeriesCompareFlyout.prototype.updateSlot = function (slotName, productTitle, productImage, productJsonPath, productLink, trigger) {
    const productSlot = this.$element.querySelector('#compareFlyoutSlot' + slotName);
    productSlot.setAttribute('data-product', productJsonPath);

    const product = new Product(productLink, productTitle);
    const datalayer = addObjectToElementDataLayer(productSlot, product.datalayerAttributes);
    const datalayerAttributes = datalayer ? ` data-cmp-clickable data-cmp-data-layer='${JSON.stringify(datalayer)}'` : '';

    if (datalayer && product) {
        const productIndex = slotName - 1;
        this.settings.compareProductsDatalayer[productIndex] = product.datalayerAttributes;
    }

    const htmlContent = `<div class="compare-flyout__slot-visual"><img src="${productImage}" alt=""/></div>
    <div class="compare-flyout__slot-title">${productTitle}</div>
    <button type="button" data-target="${trigger}"${datalayerAttributes} class="compare-flyout__slot-remove"></button>`;

    productSlot.innerHTML = htmlContent;
    initializeDatalayerByContext(productSlot, true);

    // Update state
    switch (slotName) {
    case 1:
        this.settings.productJsonSlot1 = productJsonPath;
        break;
    case 2:
        this.settings.productJsonSlot2 = productJsonPath;
        break;
    }
    this.settings.comparisonCount = this.settings.comparisonCount + 1;
    this.updateAmount(this.settings.comparisonCount);
    this.settings.liveRegion.say(productTitle + 'has been added to the comparison');

    switch (this.settings.comparisonCount) {
    case 1:
        this.showCompareFlyout();
        break;
    case 2:
        this.enableCompareButton();
        this.disableCheckboxes();
        if (!this.settings.desktopmediaQuery.matches) {
            this.expandCompareFlyout();
        }
        break;
    }
};

ProductSeriesCompareFlyout.prototype.clearSlot = function (slotName, trigger, productTitle) {
    const productSlot = this.$element.querySelector('#compareFlyoutSlot' + slotName);
    initializeDatalayerByContext(productSlot, true, false);
    const htmlContent = '<div class="compare-flyout__slot-visual"></div>';
    productSlot.innerHTML = htmlContent;

    const productIndex = slotName - 1;
    this.settings.compareProductsDatalayer[productIndex] = null;

    // Update state
    switch (parseInt(slotName)) {
    case 1:
        this.settings.productJsonSlot1 = null;
        break;
    case 2:
        this.settings.productJsonSlot2 = null;
        break;
    }

    this.settings.comparisonCount = this.settings.comparisonCount - 1;
    this.updateAmount(this.settings.comparisonCount);

    this.settings.liveRegion.say(productTitle + ' has been removed from the comparison');

    switch (this.settings.comparisonCount) {
    case 1:
        this.disableCompareButton();
        this.enableCheckboxes();
        break;
    case 0:
        this.hideCompareFlyout();
        trigger.focus();
        break;
    }
};

ProductSeriesCompareFlyout.prototype.checkProductSlots = function () {
    let productSlot;
    if (this.settings.productJsonSlot1 === null) {
        productSlot = 1;
    } else if (this.settings.productJsonSlot2 === null) {
        productSlot = 2;
    }
    return productSlot;
};

// Enable compare Button Method
ProductSeriesCompareFlyout.prototype.expandCompareFlyout = function () {
    this.settings.mobileExpandTrigger.setAttribute('aria-expanded', 'true');
    this.settings.mobileExpandTarget.classList.add('js-is-open');
    this.compensateSticky();
};

// Enable compare Button Method
ProductSeriesCompareFlyout.prototype.contractCompareFlyout = function () {
    this.settings.mobileExpandTrigger.setAttribute('aria-expanded', 'false');
    this.settings.mobileExpandTarget.classList.remove('js-is-open');
    this.compensateSticky();
};

// Enable compare Button Method
ProductSeriesCompareFlyout.prototype.handlePageSizeChange = function (instance, event) {
    if (event.matches) {
        instance.contractCompareFlyout();
        instance.compensateSticky();
    }
};

// Enable compare Button Method
ProductSeriesCompareFlyout.prototype.handlePageSizeChange = function (instance, event) {
    if (event.matches) {
        instance.contractCompareFlyout();
    }
};

ProductSeriesCompareFlyout.prototype.compensateSticky = function () {
    const compareFlyoutHeight = this.$element.offsetHeight;
    document.body.style.setProperty('padding-bottom', compareFlyoutHeight + 'px');
};

ProductSeriesCompareFlyout.prototype.updateAmount = function (amount) {
    this.settings.amountIndicator.textContent = amount;
};

ProductSeriesCompareFlyout.prototype.openCompareModal = function(modalButton, productJsonPath1, productJsonPath2) {
    try {
        // Disable button in the case the request takes long it cannot trigger other requests.
        modalButton.setAttribute('disabled', 'true');

        const templateOrigin = document.getElementById('js-product-series-comparison-modal-template');
        const templateHandlebars = Handlebars.compile(templateOrigin.innerHTML);
        const templateData = {
            'productOne': productJsonPath1,
            'productTwo': productJsonPath2
        };

        const modalTemplate = document.createElement('div');
        modalTemplate.classList.add('modal--printable');
        modalTemplate.innerHTML = templateHandlebars(templateData);

        const productsDatalayer = {'products': this.settings.compareProductsDatalayer};
        const modalElement = modalTemplate.querySelector('#js-product-series-comparison-modal');
        addObjectToElementDataLayer(modalElement, productsDatalayer, DATALAYER_ATTRIBUTE_NAME, true);
        initializeDatalayerByContext(modalTemplate, true);
        document.body.appendChild(modalTemplate);

        // Initialize modal.
        const modal = new A11yDialog(modalElement);

        // Get width of scrollbar to prevent content jump quirck.
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        modal.on('show', () => {
            // Add class to html to have a styling hook for eg:print.
            document.documentElement.classList.add('js-modal-open');
            // Disable scroll when modal is open.
            document.documentElement.style.overflowY = 'hidden';

            if (!prefersReducedMotion.matches) {
                document.body.style.paddingRight = scrollbarWidth + 'px';
            }
        });

        modal.on('hide', (node) => {
            document.documentElement.classList.remove('js-modal-open');
            // Enable scroll when modal is closed.
            document.documentElement.style.overflowY = '';

            if (!prefersReducedMotion.matches) {
                document.body.style.paddingRight = '';
            }
            node?.remove();
        });

        // Remove disabled state from trigger.
        modalButton.removeAttribute('disabled');
        // Focus trigger so that focus can be restored to trigger when modal is closed.
        modalButton.focus();
        modal.show();

        // Bind eventListener on click.
        modalButton.addEventListener('click', () => {
            modal.show();
        }, false);
    } catch (error) {
        // Disable button in order to not trigger too many requests.
        modalButton.setAttribute('disabled', 'true');
        console.warn('Modal could not be triggered', error);
    }
};

export { ProductSeriesCompareFlyout };
