import './breezometer.scss';

var GOOGLE_MAPS_API_KEY = 'AIzaSyD9ZawxKYyq6au1rj8mg257I4GJ5NDM-Ys';

(()=>{

    if (!document.querySelector('#breezometer-2')) {
        return;   
    }

    const RADIO_POLLEN_SELECTOR = '#breezometer-2-selector-pollen';
    const RADIO_AIR_QUALITY_SELECTOR = '#breezometer-2-selector-air-quality';

    let map;

/**
 * Initializes the Google Map and sets up related controls and layers.
 */
function initMap() {
    map = createMap();

    // Add a custom marker to the map
    // addCustomMarker(map);

    // Set up the event listener for map clicks to fetch air quality data
    setupMapClickListener(map);

    // Set up the tile layer for air quality and the control to toggle its visibility
    setupAirQualityTileLayer(map);

    setUpPollenTileLayer(map);

    // Add UI controls to the map
    addUIControls(map);
}

/**
 * Creates and returns a Google Map instance.
 * @return {Object} The Google Maps object.
 */
function createMap() { 
    return new google.maps.Map(document.querySelector('#breezometer-2-map'), {
        center: new google.maps.LatLng(34.0522, -118.2437),
        zoom: 10,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false
    });
}

/**
 * Adds a custom marker to the provided map.
 * @param {Object} map - The Google Maps object.A
 */
function addCustomMarker(map) {
    var location = { lat: 34.0522, lng: -118.2437 };
    new google.maps.Marker({
        position: location,
        map: map,
        title: 'Hello World!',
        icon: {
            url: 'assets/daikin.png',
            size: new google.maps.Size(100, 100),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(32, 32)
        }
    });
}

/**
 * Sets up a click listener on the map to fetch and display air quality data.
 * @param {Object} map - The Google Maps object.
 */

let infowindow = new google.maps.InfoWindow();

function setupMapClickListener(map) {
    map.addListener('click', function (mapsMouseEvent) {
        infowindow.close();
        infowindow.setContent('Hello World');
        infowindow.setPosition(new google.maps.LatLng(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng()));
        console.log('i1');
        infowindow.open(map, this);
          console.log('i2');
          fetchAirQualityData(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng());
    });




}

function constructPollenTileUrl(coord, zoom) {
    // https://developers.google.com/maps/documentation/pollen/heatmap-tiles?hl=es
    return `https://pollen.googleapis.com/v1/mapTypes/TREE_UPI/heatmapTiles/${zoom}/${coord.x}/${coord.y}?key=${GOOGLE_MAPS_API_KEY}`;
}


function setUpPollenTileLayer(map) {
    const pollenLayer = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
            var normalizedCoord = getNormalizedCoord(coord, zoom);
            if (!normalizedCoord) {
                return null;
            }
            return constructPollenTileUrl(normalizedCoord, zoom);
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 16,
        minZoom: 0,
        name: 'Pollen',
        opacity: 0.6
    });

    // Check if the checkbox is initially checked and set the layer accordingly
    var toggleControlPollen = document.querySelector(RADIO_POLLEN_SELECTOR);
    var overlayIndex = map.overlayMapTypes.push(toggleControlPollen?.checked ? pollenLayer : null) - 1;

    // Add event listener for changes on the checkbox to toggle the layer visibility
    toggleControlPollen?.addEventListener('change', function () {
        console.log('toggleControlPollen', toggleControlPollen.checked);
        map.overlayMapTypes.setAt(overlayIndex, this.checked ? pollenLayer : null);
    });
}

function handleData(lat, lng, data) {
    var iwindow= new google.maps.InfoWindow;
    
         iwindow.setContent(`${lat},${lng}`);
         iwindow.open(map,this);
    

}

/**
 * Fetches air quality data for a given latitude and longitude.
 * @param {number} lat - The latitude.
 * @param {number} lng - The longitude.
 */
function fetchAirQualityData(lat, lng) {
    var apiUrl = 'https://airquality.googleapis.com/v1/currentConditions:lookup?' + `key=${GOOGLE_MAPS_API_KEY}`;
    var postData = {
        location: { latitude: lat, longitude: lng }
    };

    fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
    })
        .then(response => response.json())
        .then(data => {
            console.log('data', data);
            // document.getElementById('info').textContent = 'Air Quality Info: ' + JSON.stringify(data);
            // handleData(lat, lng, data);
        })
        .catch(error => {
            console.error('Error:', error);
            // document.getElementById('info').textContent = 'Failed to fetch air quality data.';
        });
}

function fetchPollenData(lat, lng) {
    var apiUrl = 'https://pollen.googleapis.com/v1/currentConditions:lookup?' + `key=${GOOGLE_MAPS_API_KEY}`;
    var postData = {
        location: { latitude: lat, longitude: lng }
    };

    fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
    })
        .then(response => response.json())
        .then(data => {
            document.getElementById('info').textContent = 'Pollen Info: ' + JSON.stringify(data);
        })
        .catch(error => {
            console.error('Error:', error);
            document.getElementById('info').textContent = 'Failed to fetch pollen data.';
        });
}

/**
 * Sets up the tile layer for displaying air quality data and adds a toggle control.
 * @param {Object} map - The Google Maps object.
 */
function setupAirQualityTileLayer(map) {
    const tileLayer = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
            var normalizedCoord = getNormalizedCoord(coord, zoom);
            if (!normalizedCoord) {
                return null;
            }
            return constructTileUrl(normalizedCoord, zoom);
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 16,
        minZoom: 0,
        name: 'AirQuality',
        opacity: 0.6
    });

    // Check if the checkbox is initially checked and set the layer accordingly
    var toggleControl = document.querySelector(RADIO_AIR_QUALITY_SELECTOR);
    var overlayIndex = map.overlayMapTypes.push(toggleControl?.checked ? tileLayer : null) - 1;

    // Add event listener for changes on the checkbox to toggle the layer visibility
    toggleControl?.addEventListener('change', function () {
        console.log('toggleControlAirQuality', toggleControl.checked);
        map.overlayMapTypes.setAt(overlayIndex, this.checked ? tileLayer : null);
    });
}

/**
 * Constructs URL for the tile layer based on the map coordinates and zoom level.
 * @param {Object} coord - The coordinates object.
 * @param {number} zoom - The zoom level.
 * @return {string} The constructed URL.
 */
function constructTileUrl(coord, zoom) {
    return 'https://airquality.googleapis.com/v1/mapTypes/UAQI_RED_GREEN/heatmapTiles/' +
        `${zoom}/${coord.x}/${coord.y}?key=${GOOGLE_MAPS_API_KEY}`;
}

/**
 * Adds user interface controls to the map.
 * @param {Object} map - The Google Maps object.
 */
function addUIControls(map) {
    const toggleContainer = document.getElementById('toggleContainer');
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(toggleContainer);
}

function getNormalizedCoord(coord, zoom) {
    var y = coord.y;
    var x = coord.x;
    var tileRange = 1 << zoom;

    if (y < 0 || y >= tileRange) {
        return null;
    }

    if (x < 0 || x >= tileRange) {
        x = (x % tileRange + tileRange) % tileRange;
    }

    return { x: x, y: y };
}




if (document.readyState !== 'loading') {
    initMap();
} else {
    document.addEventListener('DOMContentLoaded', initMap);
}


})();