(function () {

    'use strict';

    function _init() {

        const buttonCaption = document.querySelector('.button__caption');

        if (!buttonCaption) {
            return;
        }
        
        const cssMultilineClass = 'button__caption--multiline';
    
        function calcMultiline(element, contentHeight) {
            let lineHeight = +window.getComputedStyle(element).lineHeight.replace('px','');
            let numberOfLines = Math.ceil(contentHeight / lineHeight);
            numberOfLines > 1 ? element.classList.add(cssMultilineClass) : element.classList.remove(cssMultilineClass);
            element.closest('.cmp-standard-header') && element.closest('.cmp-standard-header').style.setProperty('--captionHeight', `${(lineHeight * numberOfLines) + 8}px`);
        }
        
        calcMultiline(buttonCaption, buttonCaption.getBoundingClientRect().height);
    
        new ResizeObserver((entries) => {
          for (const entry of entries) {
            calcMultiline(entry.target, entry.contentRect.height);
          }
        }).observe(buttonCaption);

    }

    if (document.readyState !== 'loading') {
        _init();
    } else {
        document.addEventListener('DOMContentLoaded', _init);
    }

})();