const mapOptions = {
    zoom: 15,
    maxZoom: 19,
    zoomControl: true,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    scaleControl: true,
    scrollwheel: false,
    panControl: true,
    streetViewControl: false,
    draggable: true,
    overviewMapControl: false,
    fullscreenControl: false,
    styles: [
        {
            featureType: 'landscape',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'water',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
        },
        {
            stylers: [{ hue: '#00aaff' }, { saturation: -100 }, { gamma: 2.15 }, { lightness: 12 }],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ visibility: 'on' }, { lightness: 24 }],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ lightness: 57 }],
        },
    ],
};

export { mapOptions };
