import { buildGoogleMap } from '/src/site/utilities/google-maps';
import { mapOptions } from './dealer-contact-info-map-config';

(function () {

    'use strict';

    function initializeDealerContactInfoMap() {
        const maps = document.querySelectorAll('.js-dealer-contact-info-map');

        if (maps.length) {
            maps.forEach(mapContainer => {
                const {
                    dealerLocationLatitude,
                    dealerLocationLongitude,
                    googleMapsApiKey,
                } = mapContainer.dataset;

                const loaderOptions = {
                    apiKey: googleMapsApiKey
                };

                buildGoogleMap(mapContainer, loaderOptions, mapOptions).then((mapInstance) => {
                    // Original SVG Icon size = 57px x 75px
                    const svgMarker = {
                        path: 'M26.4464 72.306L28.5005 74.7498L31.086 71.9165C40.7547 61.079 56.9401 40.9978 56.9401 28.5311C56.9401 21.0166 53.955 13.8099 48.6415 8.49637C43.328 3.18284 36.1212 0.197754 28.6068 0.197754C21.0923 0.197754 13.8856 3.18284 8.57206 8.49637C3.25853 13.8099 0.273438 21.0166 0.273438 28.5311C0.273438 42.5915 15.3255 59.6269 23.4005 68.7644C23.5414 68.9254 23.68 69.0836 23.8161 69.239C24.8775 70.4511 25.7872 71.4899 26.4464 72.306ZM12.497 12.7976C16.7925 8.67474 22.5476 6.42343 28.5005 6.53737C34.4595 6.41393 40.2238 8.66097 44.527 12.7849C48.8302 16.9088 51.3204 22.5723 51.4506 28.5311C51.4506 35.9686 42.9151 50.0998 28.536 66.5686L27.3672 65.2582L27.2782 65.1572C20.1961 57.1144 5.58594 40.5223 5.58594 28.5311C5.71612 22.5785 8.20146 16.9206 12.497 12.7976ZM22.41 34.986C24.2109 36.1967 26.3301 36.8466 28.5001 36.8536C29.9449 36.8583 31.3764 36.5777 32.7125 36.0281C34.0487 35.4784 35.2631 34.6704 36.2864 33.6505C37.3097 32.6305 38.1216 31.4185 38.6756 30.0842C39.2295 28.7498 39.5146 27.3192 39.5146 25.8744C39.5146 23.7045 38.8717 21.5832 37.6668 19.7784C36.462 17.9737 34.7493 16.5664 32.7452 15.7344C30.741 14.9024 28.5353 14.683 26.4065 15.1037C24.2777 15.5245 22.3214 16.5666 20.7846 18.0985C19.2477 19.6304 18.1993 21.5834 17.7716 23.7108C17.344 25.8382 17.5564 28.0447 18.382 30.0515C19.2076 32.0583 20.6092 33.7754 22.41 34.986ZM25.3616 21.1799C26.2888 20.553 27.3809 20.2147 28.5001 20.2077C29.2472 20.2031 29.9879 20.3463 30.6795 20.6289C31.3711 20.9116 32 21.3283 32.53 21.8549C33.0599 22.3816 33.4804 23.0078 33.7674 23.6976C34.0544 24.3875 34.2021 25.1273 34.2021 25.8744C34.2021 26.9937 33.8707 28.0879 33.2496 29.019C32.6285 29.9501 31.7455 30.6764 30.7121 31.1063C29.6787 31.5362 28.5412 31.6504 27.4429 31.4347C26.3447 31.2189 25.335 30.6827 24.5411 29.8937C23.7472 29.1048 23.2048 28.0984 22.9821 27.0015C22.7595 25.9046 22.8666 24.7663 23.29 23.7303C23.7135 22.6942 24.4344 21.8068 25.3616 21.1799Z',
                        fillColor: '#F07300',
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: window.innerWidth < 1195 ? 0.6 : 1,
                        anchor: window.innerWidth < 1195 ?
                            new google.maps.Point(17, 45) /* Scaled:   [(57/2 x 0,6), (75 x 0,6)] */ :
                            new google.maps.Point(29, 75) /* Original: [57/2, 75] */,
                    };

                    const dealerMarker = new google.maps.Marker({
                        position: new google.maps.LatLng(dealerLocationLatitude, dealerLocationLongitude),
                        map: mapInstance,
                        icon: svgMarker
                    });

                    mapInstance.setCenter(dealerMarker.getPosition());
                    mapInstance.panTo(dealerMarker.getPosition());
                });
            });
        }
    }

    if (document.readyState !== 'loading') {
        initializeDealerContactInfoMap();
    } else {
        document.addEventListener('DOMContentLoaded', initializeDealerContactInfoMap);
    }

}());
