function matches(element, selementector) {
    return (
        element.matches ||
        element.matchesSelementector ||
        element.msMatchesSelementector ||
        element.mozMatchesSelementector ||
        element.webkitMatchesSelementector ||
        element.oMatchesSelementector
    ).call(element, selementector);
}

function findAncestor(element, selementector) {
    while ((element = element.parentElement) && !matches(element, selementector));
    return element;
}

function initDropdowns(context = document) {
    const dropdownButtons = context.querySelectorAll('.dropdown__button');
    if (dropdownButtons.length) {
        [...dropdownButtons].forEach((dropdownButton) => {
            dropdownButton.addEventListener('click', () => {
                dropdownButton.setAttribute(
                    'aria-expanded',
                    dropdownButton.getAttribute('aria-expanded') === 'false' ? 'true' : 'false'
                );

                const siblingContainer = findAncestor(
                    dropdownButton,
                    '[data-close-sibling-dropdown="true"]'
                );

                if (siblingContainer) {
                    siblingContainer.querySelectorAll('.dropdown__button[aria-expanded="true"]').forEach((siblingDropdownButton) => {
                        if (siblingDropdownButton !== dropdownButton) {
                            siblingDropdownButton.setAttribute('aria-expanded', 'false');
                        }
                    });
                }
            });
        });
    }
}

const dropdowns = document.querySelectorAll('.js-dropdown');
[...dropdowns].forEach((dropdown) => {
    initDropdowns(dropdown);
});

export { initDropdowns };
