(function () {

    'use strict';

    const duoBannerInit = () => {
        const duobanners = document.querySelectorAll('.js-duo-banner');

        if (duobanners.length) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('cmp-duo-banner--animation');
                    }
                });
            });

            [...duobanners].forEach((duobanner) => {
                duobanner.classList.add('cmp-duo-banner--animation-ready');
                observer.observe(duobanner);
            });
        }
    };

    if (document.readyState !== 'loading') {
        duoBannerInit();
    } else {
        document.addEventListener('DOMContentLoaded', duoBannerInit);
    }

}());
