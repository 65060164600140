class Product {
    constructor(link, title) {
        this.link = link;
        this.title = title;
    }

    get datalayerAttributes() {
        return {
            'xdm:productURL': this.link,
            'xdm:masterProductName': this.title
        };
    }
}

export {Product};
