import A11yDialog from 'a11y-dialog';

(function () {

    'use strict';

    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    const openNavigationSubmenuModal = (trigger, modalTitle) => {
        // Check if modal was already created to prevent a new request and DOM interactions.
        if (!trigger.hasAttribute('data-modal-initialized')) {
            try {
                // Initialize modal.
                const modalElement = document.getElementById('b2c-modal-navigation-submenu');

                // Update modal title.
                updateNavigationSubmenuModalTitle(modalTitle);

                // Listen for submenu visibility changes to trigger future modal title updates.
                const navigationSubmenuMenus = modalElement.querySelectorAll('.js-navigation-submenu-menu');
                [...navigationSubmenuMenus].forEach(navigationSubmenuMenu => {
                    const modalTitle = navigationSubmenuMenu.getAttribute('aria-labelledby');
                    if (modalTitle) {
                        new IntersectionObserver((entries) => {
                            entries.forEach(entry => {
                                if (entry.intersectionRatio > 0) {
                                    updateNavigationSubmenuModalTitle(modalTitle);
                                }
                            });
                        }).observe(navigationSubmenuMenu);
                    }
                });

                const navigationSubmenuLinks = modalElement.querySelectorAll('.js-navigation-submenu-link[href^="\#"]:not([href$="\#"]), .js-navigation-submenu-back-link[href^="\#"]:not([href$="\#"])');
                [...navigationSubmenuLinks].forEach(link => {
                    link.setAttribute('role', 'button');
                    link.setAttribute('aria-expanded', 'false');

                    link.addEventListener('click', () => {
                        link.setAttribute('aria-expanded', 'true');

                        // Remove # from link hash.
                        const hashText = link.hash.slice(1);

                        [...navigationSubmenuMenus].forEach(menu => {
                            menu.getAttribute('id') === hashText ?
                                openNavigationSubmenuTargetMenu(menu, link) :
                                closeNavigationSubmenuOtherMenus(menu);
                        });
                    });
                });

                const modal = new A11yDialog(modalElement);

                // Get width of scrollbar to prevent content jump quirck.
                const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

                modal.on('show', () => {
                    // Add class to html to have a styling hook for eg:print.
                    document.documentElement.classList.add('js-modal-open');
                    // Disable scroll when modal is open.
                    document.documentElement.style.overflowY = 'hidden';

                    if (!prefersReducedMotion.matches) {
                        document.body.style.paddingRight = scrollbarWidth + 'px';
                    }
                });

                modal.on('hide', () => {
                    document.documentElement.classList.remove('js-modal-open');
                    // Enable scroll when modal is closed.
                    document.documentElement.style.overflowY = '';

                    if (!prefersReducedMotion.matches) {
                        document.body.style.paddingRight = '';
                    }
                });

                // Focus trigger so that focus can be restored to trigger when modal is closed.
                trigger.focus();
                modal.show();

                // Set created attribute on trigger.
                trigger.setAttribute('data-modal-initialized', 'true');

                // Bind eventListener on click.
                trigger.addEventListener('click', () => {
                    modal.show();
                }, false);
            } catch (error) {
                console.warn('Modal could not be triggered', error);
            }
        }
    };

    const openNavigationSubmenuTargetMenu = (menu, link) => {
        menu.setAttribute('aria-hidden', 'false');
        link.classList.contains('js-navigation-submenu-back-link') ?
            menu.classList.add('animation--slide-in-right') :
            menu.classList.add('animation--slide-in-left');

        menu.addEventListener('animationend', () => {
            menu.querySelector('.js-navigation-submenu-link').focus();
        });
    };

    const closeNavigationSubmenuOtherMenus = (menu) => {
        menu.setAttribute('aria-hidden', 'true');
        menu.classList.remove('animation--slide-in-left');
        menu.classList.remove('animation--slide-in-right');
    };

    const updateNavigationSubmenuModalTitle = (title) => {
        const modalTitle = document.getElementById('navigation-submenu-title');
        modalTitle.textContent = title;
    };

    const initializeNavigationSubmenu = () => {
        const navigationSubmenuTriggers = document.querySelectorAll('.js-navigation-submenu-trigger');
        [...navigationSubmenuTriggers].forEach(navigationSubmenuTrigger => {
            navigationSubmenuTrigger.setAttribute('role', 'button');
            navigationSubmenuTrigger.addEventListener('click', () => {
                const modalTitle = navigationSubmenuTrigger.textContent;
                openNavigationSubmenuModal(navigationSubmenuTrigger, modalTitle);
            }, false);
        });
    };

    if (document.readyState !== 'loading') {
        initializeNavigationSubmenu();
    } else {
        document.addEventListener('DOMContentLoaded', initializeNavigationSubmenu);
    }

})();
