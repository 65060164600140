/*******************************************************************************
 * Based on https://github.com/adobe/aem-core-wcm-components/blob/main/content/src/scripts/datalayer/v1/datalayer.js
 * Used to include components that are being loaded asynchronously instead of on page load to the datalayer (e.g. modals).
 ******************************************************************************/

'use strict';

const COMPONENT_PREFIX = 'component';
const DATALAYER_ATTRIBUTE_NAME = 'cmpDataLayer';
let dataLayerEnabled;
let dataLayer;

function addComponentToDataLayer(component, contextParentElement) {
    const componentObject = getComponentObject(component, contextParentElement);
    if (componentObject) {
        dataLayer.push({
            component: componentObject
        });
    }
}

function attachDatalayerClickEventListener(element) {
    element.addEventListener('click', addClickToDataLayer);
}

function removeDatalayerClickEventListener(element) {
    element.removeEventListener('click', addClickToDataLayer);
}

function getComponentObject(element, contextParentElement) {
    const component = getComponentData(element);
    const componentID = getComponentIdFromComponentData(component);
    // if the component does not have a parent ID property, use the ID of the parent element
    if (component && componentID && component[componentID] && !component[componentID].parentId) {
        let parentNode = element.parentNode;
        if (parentNode instanceof DocumentFragment) {
            parentNode = contextParentElement;
        }
        const parentElement = parentNode?.closest('[data-cmp-data-layer], body');
        if (parentElement) {
            component[componentID].parentId = parentElement.id;
        }
    }

    return component;
}

function addClickToDataLayer(event) {
    const element = event.currentTarget;
    const componentId = getClickId(element);

    if (componentId) {
        dataLayer.push({
            event: 'cmp:click',
            eventInfo: {
                path: COMPONENT_PREFIX + '.' + componentId
            }
        });
    }
}

function getComponentData(element, dataAttributeName = DATALAYER_ATTRIBUTE_NAME) {
    const dataLayerJson = element?.dataset[dataAttributeName];
    if (dataLayerJson) {
        try {
            return JSON.parse(dataLayerJson);
        } catch (error) {
            console.error(`no valid json: ${error}`);
        }
    }
    return undefined;
}

function getComponentId(element) {
    return getComponentIdFromComponentData(getComponentData(element));
}

function getComponentIdFromComponentData(componentData = []) {
    return Object.keys(componentData)[0];
}

function getClickId(element) {
    const componentId = getComponentId(element);
    if (componentId) {
        return componentId;
    }

    const componentElement = element.closest('[data-cmp-data-layer]');
    return getComponentId(componentElement);
}

function initializeDatalayerByContext(context,
    removeExistingDatalayerElements = false,
    addNewDataLayerElements = true,
    contextParentElement = undefined) {

    dataLayerEnabled = document.body.hasAttribute('data-cmp-data-layer-enabled');
    dataLayer = (dataLayerEnabled) ? window.adobeDataLayer = window.adobeDataLayer || [] : undefined;

    if (dataLayerEnabled && dataLayer) {
        const components = context.querySelectorAll('[data-cmp-data-layer]');
        const clickableElements = context.querySelectorAll('[data-cmp-clickable]');

        components.forEach((component) => {
            const componentId = getComponentId(component);
            if (typeof componentId === 'string' && typeof dataLayer.getState === 'function') {
                if (removeExistingDatalayerElements) {
                    dataLayer.push({[COMPONENT_PREFIX]: {[componentId]: null}});
                }
                if (addNewDataLayerElements && dataLayer.getState(COMPONENT_PREFIX + '.' + componentId) === undefined) {
                    addComponentToDataLayer(component, contextParentElement);
                }
            }
        });

        clickableElements.forEach((element) => {
            if (removeExistingDatalayerElements) {
                removeDatalayerClickEventListener(element);
            }
            if (addNewDataLayerElements) {
                attachDatalayerClickEventListener(element);
            }
        });
    }
}

function addObjectToElementDataLayer(element, object, elementDatalayerAttributeName = 'jsCmpDataLayer', updateElement = false) {
    const datalayer = getComponentData(element, elementDatalayerAttributeName);
    if (typeof datalayer === 'object') {
        const componentId = getComponentIdFromComponentData(datalayer);
        if (typeof componentId === 'string') {
            datalayer[componentId] = addObjectToDatalayer(datalayer[componentId], object);
            if (updateElement) {
                element.dataset[elementDatalayerAttributeName] = JSON.stringify(datalayer);
            }
        }
    }
    return datalayer;
}

function addObjectToDatalayer(datalayer, object) {
    return {...datalayer, ...object};
}

export {addObjectToElementDataLayer, initializeDatalayerByContext, DATALAYER_ATTRIBUTE_NAME};
