import { getDefaultCarouselConfig, initializeCarousel } from '/src/site/base/carousel';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    const initializeProductSeriesCarousels = () => {
        const carousels = document.querySelectorAll('.js-product-series-carousel');

        [...carousels].forEach(carousel => {
            const config = getDefaultCarouselConfig(carousel);
            initializeCarousel(carousel, config);

            // Search for a Product which is Out of Date
            const productOutOfDate = carousel.querySelector('.js-product-series-carousel-product-out-of-date');

            if (productOutOfDate) {
                const productOutOfDateMessage = productOutOfDate.getAttribute('data-product-series-carousel-out-of-date-message');

                if (productOutOfDateMessage) {
                    const productOutOfDateHtml = `<div class="cmp-product-series-carousel__footnote container-max" aria-hidden="true">
                            <div class="container-content">
                                <div class="cmp-product-series-carousel__footnote-message">
                                    <span class="cmp-product-series-carousel__asterisk">*</span> ${productOutOfDateMessage}
                                </div>
                            </div>
                        </div>`;
                    carousel.insertAdjacentHTML('beforeend', productOutOfDateHtml);
                }
            }

            productSeriesCarouselRenderProductShotImages(carousel, undefined);
        });

        if (carousels.length) {
            document.addEventListener('productSeriesVariantChange', (event) => {
                const productSeriesId = event.detail.product;
                const carouselItem = document.querySelector(`.js-product-series-carousel-fixed-item[data-product-series-id="${productSeriesId}"]`);
                productSeriesCarouselRenderProductShotImages(carouselItem, event.detail.variant, true);
            });
        }
    };

    const productSeriesCarouselRenderProductShotImages = (wrapperElement, variantId, removeChild = false) => {
        const currentProductShot = wrapperElement?.querySelector('.js-product-series-carousel-current-productshot');
        if (currentProductShot) {
            if (variantId === undefined) {
                variantId = currentProductShot.dataset.firstVariantId;
            }
            const template = wrapperElement.querySelector('.js-product-series-carousel-productshot-template')?.content;
            const firstProductShot = template.querySelector(`[data-product-id="${variantId}"]`)?.cloneNode(true);

            if (removeChild) {
                currentProductShot.removeChild(currentProductShot.firstElementChild);
            }
            currentProductShot.appendChild(firstProductShot);
        }
    };

    if (document.readyState !== 'loading') {
        initializeProductSeriesCarousels();
    } else {
        document.addEventListener('DOMContentLoaded', initializeProductSeriesCarousels);
    }

    jsB2c.productseriescarousels = (function () {
        return {
            init: initializeProductSeriesCarousels,
        };
    })();

})();
