(function () {

    const template = document.createElement('template');

    template.innerHTML = `
        <div part="wrapper" id="cs-wrapper" class="cs-wrapper">
            <button part="button" id="cs-button" class="cs-button"></button>
            <div part="options" id="cs-options" class="cs-options">
                <div part="label" id="cs-label" class="cs-label"></div>
                <slot id="cs-slot"></slot>
            </div>
        </div>
    `;

    class CustomSelect extends HTMLElement {
		constructor() {
			super();
			this.attachShadow({ mode: 'open' });
			this.index = 0;
		}

		connectedCallback() {
            
			const { shadowRoot } = this;

			shadowRoot.appendChild(template.content.cloneNode(true));

			const customSelect = this;

            this._button = this.shadowRoot.querySelector('#cs-button');

			// Set the first option value as the default
			this.value = customSelect.querySelector('.custom-select__option')?.getAttribute('value');


			this.shadowRoot.querySelector('#cs-button').innerHTML = customSelect.querySelector('.custom-select__option')?.innerHTML;

			this.shadowRoot.querySelector('#cs-button').addEventListener('click', (event) => {

				if (customSelect.open) {
					customSelect.open = false;
					customSelect.dispatchEvent(new Event('close'));
				} else {
					customSelect.dispatchEvent(new Event('open'));
					customSelect.open = true;
                    this._setClickOutCloseListener();
				}

			});

			this.shadowRoot.querySelector('#cs-slot').addEventListener('click', (event) => {
				event.preventDefault();
				event.stopPropagation();

                if (event.target.hasAttribute('value')) {
                    this.value = event.target.getAttribute('value');
                    this._setOption(event.target.getAttribute('value'));
                }

				customSelect.open = false;

			});

            if (this.label) {
                this.shadowRoot.querySelector('#cs-label').innerHTML = this.label;
            }

            document.addEventListener('cs-click-outside', (event) => {
                this.open = false;
            });

		}

        _closeEventReceived(event) {
            this.open = false;
        }

        _handleClickOutside(event) {
            if (
                event.target.closest('button') ||
                event.target.closest('a') ||
                event.target.closest('custom-select')
            ) return false;
            document.dispatchEvent(new Event('cs-click-outside'));
        }

        _setClickOutCloseListener() {
            document.addEventListener('click', this._handleClickOutside);
        }

        _removeClickOutCloseListener() {
            document.removeEventListener('click', this._handleClickOutside);
        }

		disconnectedCallback() {
			// disconnected callback
		}

		_isBooleanAttribute(attributeName) {
            return (
                attributeName === 'open'
            )
		}

		attributeChangedCallback(attributeName, oldValue, newValue) {
			if (newValue !== oldValue) {
				this[attributeName] = this._isBooleanAttribute(attributeName) ? this.hasAttribute(attributeName) : this.getAttribute(attributeName);
			}
		}

		static get observedAttributes() {
            return [
                'value',
                'open',
                'label'
            ];
		}

        get label() {
            return this.getAttribute('label');
        }

        set label(label) {
            this.setAttribute('label', label);
            if (this.shadowRoot.querySelector('#cs-label')) {
                this.shadowRoot.querySelector('#cs-label').innerHTML = this.label;
            }
        }

		get value() {
			return this.getAttribute('value');
		}

        _getOptionNodeFromValue(value) {
            if (this.querySelector(`.custom-select__option[value="${value}"]`)) {
                return this.querySelector(`.custom-select__option[value="${value}"]`);
            }
            throw new Error(`Can't find value in this select`)
        }

        _cleanSelected() {
            if (this.querySelector('.custom-select__option--selected')) {
                this.querySelector('.custom-select__option--selected').classList.remove('custom-select__option--selected');
            }
        }

        _setOption(val) {
            try {
                const node = this._getOptionNodeFromValue(val);
                this._cleanSelected();
                node.classList.add('custom-select__option--selected');
                this.shadowRoot.querySelector('#cs-button').innerHTML = node.innerHTML;
                this.dispatchEvent(new Event('change'));
            } catch(error) {
                console.log(error);
            }
        }

		set value(val) {
			if (val) {
				this.setAttribute('value', val);
                this._setOption(val);
			} else {
				this.removeAttribute('value');
			}
		}

		get open() {
			return this.hasAttribute('open');
		}

		set open(isOpen) {
			if (isOpen) {
				this.setAttribute('open', true);
			} else {
				this.removeAttribute('open');
                this._removeClickOutCloseListener();
			}
		}

	}

    window.customElements.define('custom-select', CustomSelect);

})();