import { getDefaultCarouselConfig, initializeCarousel } from '/src/site/base/carousel';
import { initializeWatchAndLearnStory } from '/src/components/b2c/watch-and-learn-highlight/watch-and-learn-highlight';
import { initializeWatchAndLearnVideo, startWatchAndLearnVideo } from '/src/components/b2c/watch-and-learn-video/watch-and-learn-video';
import A11yDialog from 'a11y-dialog';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    function getComponentTheme(component) {
        return component.classList.contains('background--color-warm') ? 'warm' : 'cold';
    }

    function setModalTheme(modal, theme) {
        const watchAndLearnHighlight = modal.querySelector('.watch-and-learn-highlight');
        watchAndLearnHighlight.classList.add('watch-and-learn-highlight--' + theme);
        modal.classList.add('modal--' + theme);
    }

    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    async function initializeModalButton(triggerId, modalPath, modalButton, modalTitle, theme) {
        // Check if modal was already created to prevent a new request and DOM interactions.
        if (!modalButton.hasAttribute('data-modal-initialized')) {
            try {
                // Disable button in the case the request takes long it cannot trigger other requests.
                modalButton.setAttribute('disabled', 'true');

                // Get Experience Fragment and add it to document.
                const response = await fetch(modalPath);
                const html = await response.text();
                const range = document.createRange();
                const fragment = range.createContextualFragment(html);
                const modalHTML = fragment.querySelector('.modal');
                modalHTML.setAttribute('aria-label', modalTitle);
                modalHTML.setAttribute('id', 'modal-' + triggerId);
                document.body.appendChild(fragment);

                // Initialize modal.
                const modalElement = document.getElementById('modal-' + triggerId);
                const modal = new A11yDialog(modalElement);

                const watchAndLearnHighlight = modalElement.querySelector('.js-watch-and-learn-highlight, .js-watch-and-learn-video');
                const isHighlightVideo = watchAndLearnHighlight.classList.contains('js-watch-and-learn-video');
                const watchAndLearnHighlightIntro = watchAndLearnHighlight.querySelector('.cmp-watch-and-learn-highlight__intro-content');

                // Set theme.
                setModalTheme(modalElement, theme);

                // Add close button to the intro slide.
                const introCloseBtn = document.createDocumentFragment();
                const uiHolder = document.createElement('div');
                uiHolder.classList.add('cmp-watch-and-learn-highlight__ui-holder');
                const uiNav = document.createElement('div');
                uiNav.classList.add('cmp-watch-and-learn-highlight__nav');
                const closeBtn = document.createElement('button');
                closeBtn.setAttribute('type', 'button');
                closeBtn.classList.add('cmp-watch-and-learn-highlight__close-btn');
                closeBtn.classList.add('js-close-btn');

                uiNav.appendChild(closeBtn);
                uiHolder.appendChild(uiNav);
                introCloseBtn.appendChild(uiHolder);

                watchAndLearnHighlightIntro.prepend(introCloseBtn);

                // Initialise watch and learn carousel.
                (isHighlightVideo) ?
                    initializeWatchAndLearnVideo(watchAndLearnHighlight, modal) :
                    initializeWatchAndLearnStory(watchAndLearnHighlight, modal);

                // Get width of scrollbar to prevent content jump quirk.
                const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

                modal.on('show', () => {
                    // Add class to html to have a styling hook for eg:print.
                    document.documentElement.classList.add('js-modal-open');
                    // Disable scroll when modal is open.
                    document.documentElement.style.overflowY = 'hidden';

                    if (!prefersReducedMotion.matches) {
                        document.body.style.paddingRight = scrollbarWidth + 'px';
                    }
                });

                modal.on('hide', () => {
                    document.documentElement.classList.remove('js-modal-open');
                    // Enable scroll when modal is closed.
                    document.documentElement.style.overflowY = '';

                    if (!prefersReducedMotion.matches) {
                        document.body.style.paddingRight = '';
                    }
                });

                // Remove disabled state from trigger.
                modalButton.removeAttribute('disabled');
                // Focus trigger so that focus can be restored to trigger when modal is closed.
                modalButton.focus();
                modal.show();

                // Set created attribute on trigger.
                modalButton.setAttribute('data-modal-initialized', 'true');

                // Bind eventListener on click.
                modalButton.addEventListener('click', () => {
                    modal.show();

                    if (isHighlightVideo && watchAndLearnHighlight.classList.contains('js-video-started')) {
                        startWatchAndLearnVideo(watchAndLearnHighlight);
                    }

                }, false);
            } catch (error) {
                // Disable button in order to not trigger to many requests.
                modalButton.setAttribute('disabled', 'true');
                console.warn('Modal could not be triggered', error);
            }
        }
    }

    function storyCardClickHandler(story) {
        const triggerId = story.getAttribute('id');
        const parent = story.closest('.watch-and-learn-carousel');
        const theme = getComponentTheme(parent);

        const modalLabel = story.querySelector('.carousel-card__title').textContent;
        const modalPath = story.closest('.js-swiper-slide').getAttribute('data-modal-path');
        initializeModalButton(triggerId, modalPath, story, modalLabel, theme);
    }

    function initializeWatchAndLearnCarousels() {
        const carousels = document.querySelectorAll('.js-watch-and-learn-carousel');

        [...carousels].forEach(carousel => {
            const watchAndLearnItems = carousel.querySelectorAll('.js-modal-trigger');
            const config = getDefaultCarouselConfig(carousel);
            initializeCarousel(carousel, config);

            [...watchAndLearnItems].forEach(watchAndLearnItem => {
                watchAndLearnItem.addEventListener('click', () => {
                    storyCardClickHandler(watchAndLearnItem);
                }, false);
            });
        });
    }

    if (document.readyState !== 'loading') {
        initializeWatchAndLearnCarousels();
    } else {
        document.addEventListener('DOMContentLoaded', initializeWatchAndLearnCarousels);
    }

    jsB2c.watchandlearncarousels = (function () {
        return {
            init: initializeWatchAndLearnCarousels,
        };
    })();

})();
