import './custom-select.js';
import Handlebars from 'handlebars';
import { initializeDatalayerByContext } from '/src/site/utilities/datalayer-custom';

(function(){

    const SELECTOR_JS_COMPONENT = '.js-cmp-total-building-solutions-showroom';
    const SELECTOR_COMPONENT = '.cmp-total-building-solutions-showroom';
    const SELECTOR_GRID = '.cmp-total-building-solutions-showroom__grid';
    const SELECTOR_GRID_CELL = '.grid-cell';
    const SELECTOR_GRID_CARD_ROOT = '.grid-card--root';
    const SELECTOR_GRID_IMAGE = '.grid-card__image';
    const SELECTOR_GRID_TITLE = '.grid-card__title';
    const SELECTOR_GRID_DESCRIPTION = '.grid-card__description';
    const SELECTOR_GRID_SELECT = '.grid-card__select';
    const SELECTOR_GRID_FOOTER = '.grid-card__footer';
    const SELECTOR_MODAL_WIDGET = '.modal--widget';

    const SELECTOR_CTA_WIDGET = '.grid-card__link--widget';
    const SELECTOR_CTA_DIALOG = '.grid-card__link--dialog';

    const SELECTOR_TEMPLATE_GRID_CARD = '.cmp-total-building-solutions-showroom-grid-card-template';
    const SELECTOR_TEMPLATE_MODAL_DIALOG = '.cmp-total-building-solutions-showroom-modal-dialog-template';
    const SELECTOR_TEMPLATE_MODAL_WIDGET = '.cmp-total-building-solutions-showroom-modal-widget-template';

    const FALLBACK_FORCE = 'force';

    const SELECTOR_MODAL = '.modal';

    const SELECTOR_MODAL_DIALOG_CLOSE = '.modal__button--close';

    const SELECTOR_SELECT_INPUT = 'custom-select';
 
    let COMPONENT;
    let GRID;
    let GRID_CARD_TEMPLATE;
    let MODAL_DIALOG_TEMPLATE
    let MODAL_WIDGET_TEMPLATE;

    function _readFallbackJson(component) {
        try {
            let json = JSON.parse(component.dataset.fallbackResponse);
            return json;
        } catch(error) {
            return false;
        }
    }

    function _fetchData() {

        if (!COMPONENT.dataset.api) {
            _handleError(0);
            return;
        }

        fetch(
            COMPONENT.dataset.api,
            { cache: 'no-store' }
        )
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            if (_checkResponse(json)) {
                _handleResponse(json);
            } else {
                _handleError(1);
            }
        })
        .catch((error) => {
            _handleError(2, error);
        });
    }
    
    function _init() {
        COMPONENT = document.querySelector(SELECTOR_COMPONENT);

        if (!COMPONENT) return;

        GRID = COMPONENT.querySelector(SELECTOR_GRID);
        GRID_CARD_TEMPLATE = COMPONENT.querySelector(SELECTOR_TEMPLATE_GRID_CARD);
        MODAL_DIALOG_TEMPLATE = COMPONENT.querySelector(SELECTOR_TEMPLATE_MODAL_DIALOG);
        MODAL_WIDGET_TEMPLATE = COMPONENT.querySelector(SELECTOR_TEMPLATE_MODAL_WIDGET);

        const fallbackResponseData = _readFallbackJson(COMPONENT);
        if (fallbackResponseData) {
            if (fallbackResponseData._tbsFallback === FALLBACK_FORCE) {
                _handleResponse(fallbackResponseData);
            } else {
                _fetchData();
            }
        } else {
            _fetchData();
        }

    }

    function _checkResponse(response) {
        return response?.applications;
    }

    function _addGridClasses(fragment, colIndex) {
        fragment.querySelectorAll(SELECTOR_GRID_CELL).forEach((cell, rowIndex) => {
            const RowsPerCard = 5;
            const desktopCols = 3;
            const tabletSmallCols = 2;
            const adjustedColIndex = (colIndex) % desktopCols;
            const adjustedRowIndex = Math.floor((colIndex) / desktopCols) * RowsPerCard + (rowIndex + 1);
            cell.classList.add(`grid-cell__col-${adjustedColIndex + 1}--desktop`); 
            cell.classList.add(`grid-cell__row-${adjustedRowIndex}--desktop`);
            const tabletSmallAdjustedColIndex = (colIndex) % tabletSmallCols;
            const tabletSmallAdjustedRowIndex = Math.floor((colIndex) / tabletSmallCols) * RowsPerCard + (rowIndex + 1);
            cell.classList.add(`grid-cell__col-${tabletSmallAdjustedColIndex + 1}--tablet`); 
            cell.classList.add(`grid-cell__row-${tabletSmallAdjustedRowIndex}--tablet`);
            if (rowIndex === 4) {
                cell.classList.add('grid-cell--last');
            }
        })
        return fragment;
    }

    async function _handleResponse(response) {
        if (_checkResponse(response)) {
            COMPONENT.model = response;
            response.applications.forEach((application, index) => {
                let label = COMPONENT.getAttribute(`data-label-${application.units.unit_of_measure}`);
                application.label = label;
                let sizeLabel = label.replace(':','');
                application.sizeSmallLabel = application.sizes.small ? `<= ${application.sizes.small.to}` : false;
                application.sizeMediumLabel = application.sizes.medium ? `${application.sizes.medium.from} < x <= ${application.sizes.medium.to}` : false;
                application.sizeLargeLabel = application.sizes.large ? `> ${application.sizes.large.from}` : false;
                
                const computedTemplate = _createFromTemplate(GRID_CARD_TEMPLATE, application);
                const computedTemplateWithGridClasses = _addGridClasses(computedTemplate, index);
                GRID.appendChild(computedTemplateWithGridClasses);
            });
            _initDatalayer();
            _setListeners();
            _initCardState();
            _initButtonDatalayer();
            import(COMPONENT.dataset.widgetUrl).catch((error) => {
                _disableAnimationButton();
            });
        }
    }

    function _disableAnimationButton() {
        COMPONENT.querySelectorAll(SELECTOR_CTA_WIDGET).forEach((cta) => {
            cta.classList.add('grid-card__link--disabled');
            cta.setAttribute('aria-disabled', 'true');
            cta.setAttribute('tabindex', '-1');
            cta.setAttribute('disabled', true);
        });
    }

    function _initDatalayer() {
        COMPONENT.querySelectorAll(SELECTOR_GRID_IMAGE).forEach((card) => {
            initializeDatalayerByContext(card, COMPONENT);
        });
    }

    function _createFromTemplate(template, info) {
        const templateHTML = template.innerHTML;
        const templateHandlebars = Handlebars.compile(templateHTML);
        const range = document.createRange();
        const fragment = range.createContextualFragment(templateHandlebars(info));
        return fragment;
    }

    function _getSelectForCard(card) {
        return document.querySelector(`[data-application-id="${card.dataset.applicationId}"] custom-select`) || false;
    }

    function _initCardState() {
        COMPONENT.querySelectorAll(SELECTOR_GRID_CARD_ROOT).forEach((card) => {
            card.model = COMPONENT.model.applications.find(application => application.id === card.dataset.applicationId);
            const cardSelectInput = _getSelectForCard(card);
            if (cardSelectInput) {
                _handleSelectChange(cardSelectInput.value, card.dataset.applicationId);
            }
        });
    }

    function _getSolutionName(element) {
        const applicationId = element.closest('[data-application-id]').dataset.applicationId;
        return document.querySelector(`[data-application-id="${applicationId}"] ${SELECTOR_GRID_TITLE}`).innerText;
    }

    function _getSolutionSettings(element) {
        const applicationId = element.closest('[data-application-id]').dataset.applicationId;
        return document.querySelector(`[data-application-id="${applicationId}"] custom-select`).value;
    }

    function _getParentId(element) {
        const parent = element.closest('[data-cmp-data-layer]');
        const parentDatalayer = JSON.parse(parent.dataset.cmpDataLayer);
        return Object.keys(parentDatalayer)[0];
    }

    function _getRepoModifyDate(element) {
        const parent = element.closest('[data-cmp-data-layer]');
        const parentDatalayer = JSON.parse(parent.dataset.cmpDataLayer);
        return parentDatalayer[_getParentId(element)]['repo:modifyDate'];
    }

    function _setListeners() {
        const widgetLinks = COMPONENT.querySelectorAll(SELECTOR_CTA_WIDGET);
        const dialogLinks = COMPONENT.querySelectorAll(SELECTOR_CTA_DIALOG);

        widgetLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                if (event.target.hasAttribute('disabled')) return;
                const applicationId = _getApplicationId(link);
                window.adobeDataLayer.push({
                    event: 'cmp:click',
                    eventInfo: {
                        path: `component.${_getParentId(event.target.closest(SELECTOR_JS_COMPONENT))}-button-${applicationId}-discover`,
                        // solutionName: _getSolutionName(event.target),
                        solutionSettings: _getSolutionSettings(event.target)
                    }
                });
                _handleWidgetCta(event);
            });
        });

        dialogLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const applicationId = _getApplicationId(link);
                window.adobeDataLayer.push({
                    event: 'cmp:click',
                    eventInfo: {
                        path: `component.${_getParentId(event.target.closest(SELECTOR_JS_COMPONENT))}-button-${applicationId}-simulate-lifecycle`,
                        // solutionName: _getSolutionName(event.target),
                        solutionSettings: _getSolutionSettings(event.target)
                    }
                });
                _handleModal(event);
            });
        });

        COMPONENT.querySelectorAll(SELECTOR_SELECT_INPUT).forEach((select) => {
            select.addEventListener('change', (event) => {
                _handleSelectChange(event.target.value, event.target.closest('[data-application-id]').dataset.applicationId);
            });
        });

    }

    async function _handleWidgetCta(event) {
        document.documentElement.classList.add('js-modal-open');
        document.documentElement.style.overflowY = 'hidden';
        _openModalWidget(event);
    }

    function _openModalWidget(event) {
        COMPONENT.appendChild(_createFromTemplate(MODAL_WIDGET_TEMPLATE, {
            tbs: _getTbs(_getApplicationId(event.target))
        }));
        COMPONENT.querySelectorAll('standalone-lcs-video-animations-tool')[0].addEventListener('aaltra.lcsVideoAnimations.done', _closeModalWidget);
    }

    function _closeModalWidget() {
        COMPONENT.querySelector(SELECTOR_MODAL_WIDGET).remove();
        document.documentElement.classList.remove('js-modal-open');
        document.documentElement.style.overflowY = '';
    }

    function _getTbs(applicationId) {
        return document.querySelector(`${SELECTOR_GRID_CARD_ROOT}[data-application-id="${applicationId}"]`).dataset.tbs;
    }

    function _getApplicationId(element) {
        return element.closest('[data-application-id]').dataset.applicationId;
    }

    function _handleModal(event) {
        const COMPONENT = document.querySelector(SELECTOR_COMPONENT);
        document.documentElement.classList.add('js-modal-open');
        document.documentElement.style.overflowY = 'hidden';

		COMPONENT.appendChild(_createFromTemplate(MODAL_DIALOG_TEMPLATE, {
            tbs: _getTbs(_getApplicationId(event.target)),
        }));

        initializeDatalayerByContext(COMPONENT.querySelector(SELECTOR_MODAL), COMPONENT);

        COMPONENT.querySelector(SELECTOR_MODAL_DIALOG_CLOSE).addEventListener('click', (event) => {
			event.preventDefault();
            _handleCloseModal(event);
    	});

    }

    function _initButtonDatalayer() {
        
        COMPONENT.querySelectorAll('.grid-card__link').forEach((button) => {

            const dcTitle = button.classList.contains('grid-card__link--widget') ? 'Discover' : 'Simulate lifecycle';
            const dcTitleSlug = dcTitle.toLowerCase().replace(' ', '-');

            const buttonDatalayer = {
                '@type': 'b2c/components/total-building-solutions-showroom-composite/button',
                'dc:title': dcTitle,
                'parentId': _getParentId(button.closest(SELECTOR_JS_COMPONENT)),
                'solutionName': _getSolutionName(button),
                // 'solutionSettings': _getSolutionSettings(button),
                'repo:modifyDate': _getRepoModifyDate(button),
                'xdm:linkURL': '#'
            };

            button.dataset.cmpDataLayer = JSON.stringify(buttonDatalayer);

            const datalayerComponentObject = {};
            const applicationId = _getApplicationId(button);
            datalayerComponentObject[`${buttonDatalayer.parentId}-button-${applicationId}-${dcTitleSlug}`] = buttonDatalayer;
            
            if (adobeDataLayer) {
                adobeDataLayer.push({
                    'component': datalayerComponentObject
                });
            }

        });
    }

    function _handleCloseModal(event) {
        const MODAL = event.target.closest(SELECTOR_MODAL);
        MODAL.remove();
        document.documentElement.classList.remove('js-modal-open');
        document.documentElement.style.overflowY = '';
    }

    function _handleSelectChange(selectedSize, cardId) {

        const card = document.querySelector(`${SELECTOR_GRID_CARD_ROOT}[data-application-id="${cardId}"]`);

        const totalBuildingSolution = card.model.total_building_solutions.find((item) => {
            return item.size === selectedSize && item.benchmark !== true
        });

        if (!totalBuildingSolution) return;

        if (totalBuildingSolution?.id) card.dataset.tbs = totalBuildingSolution.id;
        if (totalBuildingSolution?.image) document.querySelector(`[data-application-id="${cardId}"] ${SELECTOR_GRID_IMAGE}`).src = totalBuildingSolution.image;
        if (totalBuildingSolution?.description) document.querySelector(`[data-application-id="${cardId}"] ${SELECTOR_GRID_DESCRIPTION}`).innerHTML = totalBuildingSolution.description;

    }

    function _handleError(code, error) {
        if (COMPONENT.dataset.fallbackResponse) {
            _handleResponse(JSON.parse(COMPONENT.dataset.fallbackResponse));
		} else {
            console.log(code);
            console.log(error);
		}
    }

    if (document.readyState !== 'loading') {
        _init();
    } else {
        document.addEventListener('DOMContentLoaded', _init);
    }

})();