import { shouldResetCookies, isXfPage } from './cookie-commons';
import { initCookieModal } from './cookie-modal/cookie-modal';
import { initCookiePreferences } from './cookie-preferences/cookie-preferences';

(function () {

    'use strict';

    function initCookieSettings() {
        const cookieModal = document.querySelector('.js-cookie-modal');
        const cookiePreferences = document.querySelector('.js-cookie-preferences');

        if (cookieModal && (shouldResetCookies() || isXfPage())) {
            initCookieModal();
        }

        if (cookiePreferences) {
            initCookiePreferences();
        }
    }

    if (document.readyState !== 'loading') {
        initCookieSettings();
    } else {
        document.addEventListener('DOMContentLoaded', initCookieSettings);
    }

}());
