(function () {

    'use strict';

    const initUserEvents = () => {
        function userIsTabbing(event) {
            if (event.key === 'Tab') {
                document.body.classList.add('js-user-is-tabbing');

                window.removeEventListener('keydown', userIsTabbing);
                window.addEventListener('mousedown', userIsNotTabbing);
            }
        }

        function userIsNotTabbing() {
            document.body.classList.remove('js-user-is-tabbing');

            window.removeEventListener('mousedown', userIsNotTabbing);
            window.addEventListener('keydown', userIsTabbing);
        }

        window.addEventListener('keydown', userIsTabbing);
    };

    if (document.readyState !== 'loading') {
        initUserEvents();
    } else {
        document.addEventListener('DOMContentLoaded', initUserEvents);
    }

})();
