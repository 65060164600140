(function () {

    'use strict';
 
    const init = () => {
        
        const CLASS_SHOW_STICKY = 'cmp-sticky-nav__wrapper--show';
        const CLASS_STICKY_NAV_ITEM = 'sticky-nav__item';
        const CLASS_STICKY_NAV_TITLE = 'sticky-nav__title';
        const CLASS_STICKY_NAV_TO_PARENT_LINK = 'sticky-nav__to-parent-link';
        const CLASS_STICKY_NAV_PRIMARY_BUTTON = 'sticky-nav__primary-button';
        const CLASS_STICKY_NAV_SECONDARY_BUTTON = 'sticky-nav__secondary-button';
       
        const headerComp = document.querySelector('.js-sticky-navigation--enabled');
        const stickyNavWrapper = document.querySelector('.js-cmp-sticky-nav__wrapper');

        if (!headerComp || !stickyNavWrapper) {
            return;
        }

        let isProductSeriesHeader = headerComp.classList.contains('product-series-header');

        let headerToParentLink;
        let headerTitle;
        let stickyNavSecondaryButton;
        let stickyNavPrimaryButton;

        if (isProductSeriesHeader) {
            headerToParentLink = headerComp.querySelector('.sticky-nav-to-parent-link');
            headerTitle = headerComp.querySelector('.cmp-product-series-header__name');
            stickyNavSecondaryButton = headerComp.querySelector('.sticky-nav-secondary-button .cmp-button');
            stickyNavPrimaryButton = headerComp.querySelector('.sticky-nav-primary-button .cmp-button');    
        } else {
            headerToParentLink = headerComp.querySelector('.cmp-to-parent-link__link') ?  headerComp.querySelector('.sticky-nav-to-parent-link') : false;
            headerTitle = headerComp.querySelector('.sticky-nav-title');
            stickyNavSecondaryButton = headerComp.querySelector('.sticky-nav-secondary-button .cmp-button');
            stickyNavPrimaryButton = headerComp.querySelector('.sticky-nav-primary-button .cmp-button');    
        }

        if (!stickyNavPrimaryButton && !stickyNavSecondaryButton) {
            console.log('sticky-nav: No sticky navigation buttons found');
            return;
        }

        let stickyNav = stickyNavWrapper.querySelector('.cmp-sticky-nav');

        let isWarm = 
            document.querySelector('.sticky-nav-primary-button')?.classList.contains('has-button--style-warm') || 
            document.querySelector('.product-series-header')?.classList.contains('product-series-header--warm');

        if (headerToParentLink) {
            let stickyNavToParentLink = headerToParentLink.cloneNode(true);
            stickyNavToParentLink.classList.add(CLASS_STICKY_NAV_ITEM);
            stickyNavToParentLink.classList.add(CLASS_STICKY_NAV_TO_PARENT_LINK);
            if (isWarm) {
                stickyNavToParentLink.classList.add(`${CLASS_STICKY_NAV_TO_PARENT_LINK}--warm`);
            }
            stickyNav.appendChild(stickyNavToParentLink);
        }

        if (headerTitle) {
            let stickyNavTitle;
            if (isProductSeriesHeader) {
                stickyNavTitle = document.createElement('div');
                stickyNavTitle.appendChild(headerTitle.cloneNode(true));
            } else {
                stickyNavTitle = headerTitle.cloneNode(true);
            }
            stickyNavTitle.classList.add(CLASS_STICKY_NAV_ITEM);
            stickyNavTitle.classList.add(CLASS_STICKY_NAV_TITLE);
            stickyNav.appendChild(stickyNavTitle);
        }

        if (stickyNavSecondaryButton) {
            let div = document.createElement('div');
            div.classList.add('button', 'has-button-link');
            if (isWarm) { 
                div.classList.add('has-button-link--style-warm');
            }
            div.classList.add(CLASS_STICKY_NAV_ITEM, CLASS_STICKY_NAV_SECONDARY_BUTTON);
            div.appendChild(stickyNavSecondaryButton.cloneNode(true));
            stickyNav.appendChild(div);
        }

        if (stickyNavPrimaryButton) {
            let div = document.createElement('div')
            div.classList.add('primary-button', 'button', 'has-button');
            if (isWarm) {
                div.classList.add('has-button--style-warm');
            }
            div.classList.add(CLASS_STICKY_NAV_ITEM, CLASS_STICKY_NAV_PRIMARY_BUTTON);
            div.appendChild(stickyNavPrimaryButton.cloneNode(true));
            stickyNav.appendChild(div);
        }
    
        /**
         * IntersectionObserver to show/hide sticky navigation
         */
        new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        stickyNavWrapper.classList.remove(CLASS_SHOW_STICKY);
                    } else {
                        stickyNavWrapper.classList.add(CLASS_SHOW_STICKY);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: [0]
            }
        ).observe(headerComp);
    }

    if (document.readyState !== 'loading') {
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }

})();

document.addEventListener('click', (event) => {
	if(event.target.closest('.cmp-sticky-nav [data-modal-path]')) {
        let eventButton = event.target.closest('.cmp-sticky-nav [data-modal-path]');
        let eventButtonComponent = eventButton.closest('.button');
        if (eventButtonComponent.classList.contains('sticky-nav__primary-button')) {
            document.querySelector('.sticky-nav-primary-button .cmp-button').click();
            return;
        }
        if (eventButtonComponent.classList.contains('sticky-nav__secondary-button')) {
            document.querySelector('.sticky-nav-secondary-button .cmp-button').click();
            return;
        }
	}
}) 