import A11yDialog from 'a11y-dialog';
import { initializeDatalayerByContext } from '/src/site/utilities/datalayer-custom';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    function fillFormFields(modalButton, fragmentModalEl) {
        const formIdField = modalButton.dataset.formIdField;
        const formNameField = modalButton.dataset.formNameField;
        const formEmailField = modalButton.dataset.formEmailField;
        const dealerId = modalButton.dataset.prefillFormDealerId;
        const dealerName = modalButton.dataset.prefillFormDealerName;
        const dealerEmail = modalButton.dataset.prefillFormDealerEmail;
        const iframe = fragmentModalEl.querySelector('iframe');

        if (iframe) {
            const originalSrc = iframe.getAttribute('src');
            let url = new URL(originalSrc);
            if (formIdField && dealerId) {
                url.searchParams.set(formIdField, dealerId);
            }
            if (formNameField && dealerName) {
                url.searchParams.set(formNameField, dealerName);
            }
            if (formEmailField && dealerEmail) {
                url.searchParams.set(formEmailField, dealerEmail);
            }
            iframe.setAttribute('src', url.toString());
        }
    }

    async function initializeModalButton(modalPath, modalButton, modalButtonId) {
        // Check if modal was already created to prevent a new request and DOM interactions.
        if (!modalButton.hasAttribute('data-modal-initialized')) {
            try {
                // Disable button in the case the request takes long it cannot trigger other requests.
                modalButton.setAttribute('disabled', 'true');
                // Get XPF and add it to document.
                const response = await fetch(modalPath);
                const html = await response.text();
                const fragment = new Range().createContextualFragment(html);
                // Set unique ID on modal based on trigger.
                // Make sure modal title is unique and aria-labbeledBy is correct.
                const fragmentModalEl = fragment.querySelector('.modal');
                const fragmentTitle = fragment.querySelector('.modal__title');
                const fragmentTitleId = fragmentTitle.getAttribute('id');
                fragmentTitle.setAttribute('id', fragmentTitleId + '-' + modalButtonId);
                fragmentModalEl.setAttribute('aria-labelledby', fragmentTitleId + '-' + modalButtonId);

                // Dealer Page - Dealer Contact CTA.
                if (modalButton.hasAttribute('data-prefill-form')) {
                    fillFormFields(modalButton, fragmentModalEl);
                }

                initializeDatalayerByContext(fragment, false, true, document.body);
                document.body.appendChild(fragment);

                // Initialize modal.
                const modal = new A11yDialog(fragmentModalEl);

                // Get width of scrollbar to prevent content jump quirck.
                const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

                modal.on('show', () => {
                    // Add class to html to have a styling hook for eg:print.
                    document.documentElement.classList.add('js-modal-open');
                    // Disable scroll when modal is open.
                    document.documentElement.style.overflowY = 'hidden';

                    if (!prefersReducedMotion.matches) {
                        document.body.style.paddingRight = scrollbarWidth + 'px';
                    }
                });

                modal.on('hide', () => {
                    document.documentElement.classList.remove('js-modal-open');
                    // Enable scroll when modal is closed.
                    document.documentElement.style.overflowY = '';

                    if (!prefersReducedMotion.matches) {
                        document.body.style.paddingRight = '';
                    }
                });

                // Remove disabled state from trigger.
                modalButton.removeAttribute('disabled');
                // Focus trigger so that focus can be restored to trigger when modal is closed.
                modalButton.focus();
                modal.show();

                // Set created attribute on trigger.
                modalButton.setAttribute('data-modal-initialized', 'true');

                // Bind eventListener on click.
                modalButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    modal.show();
                }, false);

                const pardotForm = document.querySelector('#pardotform');
                const queryStringForPardot = window.location.search;
                if (pardotForm && queryStringForPardot) {
                    pardotForm.setAttribute("src", pardotForm.getAttribute('src')+queryStringForPardot);  
                }


            } catch (error) {
                // Disable button in order to not trigger to many requests.
                modalButton.setAttribute('disabled', 'true');
                console.warn('Modal could not be triggered', error);
            }
        }
    }

    function initializeModalButtons() {
        const modalButtons = document.querySelectorAll('.js-button-modal');

        [...modalButtons].forEach(modalButton => {
            if (!modalButton.hasAttribute('data-modal-path')) {
                return;
            }

            const modalPath = modalButton.getAttribute('data-modal-path');
            const modalButtonId = modalButton.getAttribute('id');

            if (modalPath) {
                modalButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    initializeModalButton(modalPath, modalButton, modalButtonId);
                }, false);
                modalButton.classList.add('js-button-modal-event-bound');
            }
        });
    }


    jsB2c.buttons = (function() {
        return {
            init: initializeModalButtons
        };
    })();

    if (document.readyState !== 'loading') {
        initializeModalButtons();
    } else {
        document.addEventListener('DOMContentLoaded', initializeModalButtons);
    }

})();
