import { getDefaultCarouselConfig, initializeCarousel } from '/src/site/base/carousel';

(function () {

    'use strict';

    function initializeWideContentCarousels() {
        const carousels = document.querySelectorAll('.js-wide-content-carousel');

        [...carousels].forEach(carousel => {
            const config = getDefaultCarouselConfig(carousel);
            initializeCarousel(carousel, config);
        });
    }

    if (document.readyState !== 'loading') {
        initializeWideContentCarousels();
    } else {
        document.addEventListener('DOMContentLoaded', initializeWideContentCarousels);
    }

})();
