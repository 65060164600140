(function () {

    'use strict';

    function initializeTabsSwipe() {
        const tabsListContainers = document.querySelectorAll('.js-tablist-container');

        [...tabsListContainers].forEach(tabsListContainer => {
            const buttonPrev = tabsListContainer.querySelector('.tablist__swipe--left');
            const buttonNext = tabsListContainer.querySelector('.tablist__swipe--right');
            const tabList = tabsListContainer.querySelector('.cmp-tabs__tablist');
            const tabsArray = tabList.querySelectorAll('.cmp-tabs__tab');
            const firstTab = tabsArray[0];
            const lastTab = tabsArray[tabsArray.length - 1];
            let tabListClientWidth = tabList.clientWidth;

            //Show or hide left and right buttons.
            const tabButtonPairs = [
                {
                    tabElement: firstTab,
                    button: buttonPrev
                },
                {
                    tabElement: lastTab,
                    button: buttonNext
                }
            ];

            tabButtonPairs.forEach(pair => {
                const observer = new IntersectionObserver(buttonHandler, {threshold: 0.75});

                function buttonHandler(entries) {
                    entries.map((entry) => {
                        entry.isIntersecting ? pair.button.classList.add('hidden') : pair.button.classList.remove('hidden');
                    });
                }

                observer.observe(pair.tabElement);
            });

            //On button click: scroll overflowing tabs into view.
            buttonNext.addEventListener('click', () => {
                tabList.scrollLeft += tabListClientWidth;
            });

            buttonPrev.addEventListener('click', () => {
                tabList.scrollLeft -= tabListClientWidth;
            });

            //When keyboard navigating: scroll overflowing tabs into view when focused.
            [...tabsArray].forEach(tabElement => {
                tabElement.addEventListener('focus', () => {
                    tabElement.scrollIntoView({behavior: 'smooth', inline: 'center'});
                });
            });

            // Recalculate tabListClientWidth on screen resize.
            window.addEventListener('resize', () => {
                tabListClientWidth = tabList.clientWidth;
            });
        });
    }

    if (document.readyState !== 'loading') {
        initializeTabsSwipe();
    } else {
        document.addEventListener('DOMContentLoaded', initializeTabsSwipe);
    }
})();
