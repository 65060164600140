(function () {

    'use strict';

    const initLanguageNavigation = () => {
        const languageNavigationDropdownButton = document.querySelector('.js-navigation-segments__link-language');
        const languageNavigationDropdown = document.querySelector('.js-navigation-segments__language-dropdown');
        const languageNavigationDropdownCloseButton = languageNavigationDropdown?.querySelector('.js-language-dropdown__close');

        if (languageNavigationDropdownButton && languageNavigationDropdown && languageNavigationDropdownCloseButton) {
            languageNavigationDropdownButton.addEventListener('click', (event) => {
                event.preventDefault();
                languageNavigationDropdown.classList.toggle('navigation-segments__language-dropdown--open');
            });
            languageNavigationDropdownCloseButton.addEventListener('click', (event) => {
                event.preventDefault();
                languageNavigationDropdown.classList.remove('navigation-segments__language-dropdown--open');
            });
        }
    };

    if (document.readyState !== 'loading') {
        initLanguageNavigation();
    } else {
        document.addEventListener('DOMContentLoaded', initLanguageNavigation);
    }

})();