(function () {

    'use strict';

    const initNavigationMobile = () => {
        const hamburgerButton = document.querySelector('.js-navigation-topmenu');
        const mobileMenu = document.querySelector('.js-navigation-mobile');
        const menus = mobileMenu != null ? [...mobileMenu.querySelectorAll('.js-navigation-mobile-menu')] : [];
        const allMenuLinks = mobileMenu != null ? [...mobileMenu.querySelectorAll('.js-navigation-mobile-link, .js-navigation-mobile-back-link')] : [];
        const menuLinks = mobileMenu != null ? [...mobileMenu.querySelectorAll('.js-navigation-mobile-link[href^="\#"]:not([href$="\#"]), .js-navigation-mobile-back-link[href^="\#"]:not([href$="\#"])')] : [];

        if (hamburgerButton) {
            // Initial setup.
            mobileMenu.classList.add('hidden');
            hamburgerButton.setAttribute('role', 'button');
            [...allMenuLinks].forEach(link => {
                link.addEventListener('keydown', (event) => {
                    if (event.key === ' ') {
                        link.click();
                    }
                });
            });

            // Toggle menu.
            hamburgerButton.addEventListener('click', () => {
                toggleMobileMenu();
            });

            // Toggle menu when spacebar pressed.
            hamburgerButton.addEventListener('keyup', (event) => {
                if (event.key === ' ') {
                    toggleMobileMenu();
                }
            });
        }

        // Close menu with esc.
        document.addEventListener('keydown', (event) => {
            let isMobileMenuOpen = hamburgerButton.getAttribute('aria-expanded') === 'true';

            if (event.key === 'Escape' && isMobileMenuOpen) {
                document.body.classList.remove('js-mobile-menu-open');
                mobileMenu.classList.add('hidden');
                mobileMenu.setAttribute('aria-hidden', 'true');
                toggleAriaProperty(hamburgerButton, 'aria-expanded');
                [...menus].forEach(menu => {
                    menu.setAttribute('aria-hidden', 'true');
                });
                hamburgerButton.focus();
            }
        });

        // Toggle aria-hidden.
        menuLinks.forEach(link => {
            link.addEventListener('click', () => {
                toggleAriaProperty(link, 'aria-expanded');

                // Remove # from link hash.
                const hashText = link.hash.slice(1);

                [...menus].forEach(menu => {
                    menu.getAttribute('id') === hashText ?
                        openTargetMenu(menu, link) :
                        closeOtherMenus(menu);
                });
            });
        });

        // Tab navigation flow.
        [...menus].forEach(menu => {
            const lastFocusElement = menu.querySelector('.js-navigation-mobile-back-link') ?? menu.querySelector('li:last-child a');
            if (lastFocusElement) {
                lastFocusElement.addEventListener('keydown', (event) => {
                    if (event.key === 'Tab') {
                        event.preventDefault();
                        toggleMobileMenu();
                    }
                });
            }
        });

        const toggleAriaProperty = (element, ariaLabel) => {
            element.getAttribute(ariaLabel) === 'true' ?
                element.setAttribute(ariaLabel, 'false') :
                element.setAttribute(ariaLabel, 'true');
        };

        const toggleMobileMenu = () => {
            document.body.classList.toggle('js-mobile-menu-open');
            mobileMenu.classList.toggle('hidden');
            mobileMenu.classList.toggle('animation--slide-in-left');
            toggleAriaProperty(hamburgerButton, 'aria-expanded');
            toggleAriaProperty(mobileMenu, 'aria-hidden');
            menuLinks.forEach(link => {
                link.setAttribute('role', 'button');
                link.setAttribute('aria-expanded', 'false');
            });
        };

        const openTargetMenu = (menu, link) => {
            menu.setAttribute('aria-hidden', 'false');
            link.classList.contains('js-navigation-mobile-back-link') ?
                menu.classList.add('animation--slide-in-right') :
                menu.classList.add('animation--slide-in-left');

            menu.addEventListener('animationend', () => {
                if (document.body.classList.contains('js-user-is-tabbing')) {
                    menu.querySelector('.js-navigation-mobile-link').focus();
                }
            });
        };

        const closeOtherMenus = (menu) => {
            menu.setAttribute('aria-hidden', 'true');
            menu.classList.remove('animation--slide-in-left');
            menu.classList.remove('animation--slide-in-right');
        };

        initSearch();
    };

    if (document.readyState !== 'loading') {
        initNavigationMobile();
    } else {
        document.addEventListener('DOMContentLoaded', initNavigationMobile);
    }

    function initSearch() {
        /*let openSearchBtn = document.querySelector('.navigation-mobile__button--search');
        let navMobile = document.querySelector('.navigation-mobile');
        let btnOverlay = document.getElementById('navSearchBtnOverlay');

        if (!openSearchBtn) {return;}

        openSearchBtn.addEventListener('click', () => { 
            navMobile.classList.add('not-display');
            btnOverlay.classList.remove('not-display');
            btnOverlay.classList.add('overlay-search');
            document.querySelector('.cmp-container').classList.add('show');
            document.querySelector('.container').classList.add('show');
        });

        let closeBtn = document.querySelector('.close-button');
        closeBtn.addEventListener('click', () => { 
            document.querySelector('.cmp-container').classList.remove('show');
            document.querySelector('.container').classList.remove('show');
            btnOverlay.classList.add('not-display');
            btnOverlay.classList.remove('overlay-search');
            document.querySelector('.navigation-mobile').classList.remove('not-display');
        });*/
    }

   
})();
