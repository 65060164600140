(function () {

    'use strict';

    function initializeDealerContactInfoShowContactOptions() {
        const contactOptions = document.querySelectorAll('.js-dealer-contact-info-contact-options');

        if (contactOptions.length) {
            contactOptions.forEach(contactOptionsContainer => {
                const contactOptionsTarget = contactOptionsContainer.querySelector('.js-dealer-contact-info-contact-options-target');
                const contactOptionsTrigger = contactOptionsContainer.querySelector('.js-dealer-contact-info-contact-options-trigger');

                if (contactOptionsTarget && contactOptionsTrigger) {
                    contactOptionsTrigger.addEventListener('click', () => {
                        contactOptionsTarget.classList.remove('hidden');
                        contactOptionsTrigger.parentNode.removeChild(contactOptionsTrigger);
                    }, false);
                }
            });
        }
    }

    if (document.readyState !== 'loading') {
        initializeDealerContactInfoShowContactOptions();
    } else {
        document.addEventListener('DOMContentLoaded', initializeDealerContactInfoShowContactOptions);
    }

}());
