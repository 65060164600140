import { initializeCarousel } from '/src/site/base/carousel';
import { getProductSeriesGalleryCarouselConfig } from './product-series-gallery-config';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    // Helper function to show relevant slides
    function getFilteredSlides(slides, productVariant) {
        if (productVariant) {
            return slides.filter(productSlide => productSlide.dataset.productVariant === productVariant);
        }
        return slides.filter(productSlide => productSlide.dataset.defaultProductVariant === 'true');
    }

    function updateProductSeriesGallery(productGallery, productVariant) {
        let swiperInstance = productGallery.querySelector('.js-swiper').swiper;
        const swiperProductSlides = swiperInstance.productSlides;

        const activeIndex = swiperInstance.realIndex;
        // Get new slides and check if loop should be true
        const newSlides = getFilteredSlides(swiperProductSlides, productVariant);
        const newConfig = { loop: newSlides.length > 1 };
        let swiperConfig = { ...swiperInstance.params, ...newConfig };

        // Remove old slides
        swiperInstance.removeAllSlides();
        // Add new slides to swiper
        for (const newSlide of newSlides) {
            swiperInstance.appendSlide(newSlide);
        }
        // Destroy old instance
        swiperInstance.destroy(true, true);

        // Create a new swiper instance with updated settings
        swiperInstance = initializeCarousel(productGallery, swiperConfig);
        swiperInstance.productSlides = swiperProductSlides;
        swiperInstance.update();

        // If there are equal amount of slides navigate to the active slide
        // Otherwise navigate to the first one
        if (swiperInstance.params.loop && activeIndex <= newSlides.length) {
            swiperInstance.slideToLoop(activeIndex, 0, false);
        } else {
            swiperInstance.slideTo(0, 0, false);
        }
        // Return the swiper instance so it can be manipulated
        return swiperInstance;
    }

    function initProductSeriesGalleries() {
        const productGalleries = document.querySelectorAll('.js-product-series-gallery-carousel');

        [...productGalleries].forEach(productGallery => {
            const config = getProductSeriesGalleryCarouselConfig(productGallery);
            const swiperSlides = productGallery.querySelectorAll('.js-swiper-slide');

            // Remove other slides from DOM
            productGallery.querySelectorAll('.js-swiper-slide:not(.js-swiper-slide[data-default-product-variant])').forEach(slide => slide.remove());

            let swiperInstance = initializeCarousel(productGallery, config);
            swiperInstance.productSlides = Array.from(swiperSlides);
        });

        document.addEventListener('productSeriesVariantChange', (event) => {
            const targetProductGallery = document.querySelector('.js-product-series-gallery-carousel[data-product-id="' + event.detail.product + '"');
            if (targetProductGallery) {
                updateProductSeriesGallery(targetProductGallery, event.detail.variant);
            }
        });
    }

    if (document.readyState !== 'loading') {
        initProductSeriesGalleries();
    } else {
        document.addEventListener('DOMContentLoaded', initProductSeriesGalleries);
    }

    jsB2c.productSeriesGallery = (function() {
        return {
            init: initProductSeriesGalleries,
        };
    })();

})();
