import { Navigation, A11y, Pagination, EffectFade, Lazy, Manipulation } from 'swiper';

function getProductSeriesGalleryCarouselConfig(carousel) {
    const swiperElement = carousel.querySelector('.js-swiper');
    const swiperSlides = carousel.querySelectorAll('.js-swiper-slide');

    // Next and previous Buttons are outside the swiper container
    const carouselNextButton = carousel.querySelector('.js-swiper-button-next');
    const carouselPrevButton = carousel.querySelector('.js-swiper-button-prev');
    const carouselPagination = carousel.querySelector('.js-swiper-pagination');

    return {
        modules: [Navigation, Pagination, EffectFade, Lazy, Manipulation, A11y],
        loop: swiperSlides.length > 1,
        watchOverflow: true,
        preloadImages: true,
        pagination: {
            el: carouselPagination,
            type: 'bullets',
            clickable: 'true',
            bulletActiveClass: 'product-series-gallery__pagination-indicator--active',
            bulletClass: 'product-series-gallery__pagination-indicator',
            hiddenClass: 'hidden',
            horizontalClass: 'product-series-gallery__pagination--horizontal',
            lockClass: 'product-series-gallery__pagination--locked',
            modifierClass: 'product-series-gallery__pagination--',
            paginationDisabledClass: 'disabled'
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            disabledClass: 'product-series-gallery__icon--disabled',
            nextEl: carouselNextButton,
            prevEl: carouselPrevButton,
        },
        a11y: {
            // Labels are set on the swiper instance through data attributes
            prevSlideMessage: swiperElement.dataset.prevSlideMessage,
            nextSlideMessage: swiperElement.dataset.nextSlideMessage,
            containerMessage: swiperElement.dataset.containerMessage,
            slideLabelMessage: swiperElement.dataset.slideLabelMessage + ' {{index}} / {{slidesLength}}'
        }, on: {
            afterInit: function (event) {
                // Workaround for bug in Swiper JS
                if (event.params.loop) {
                    event.params.navigation.nextEl.removeAttribute('aria-disabled');
                    event.params.navigation.nextEl.removeAttribute('disabled');
                    event.params.navigation.prevEl.removeAttribute('aria-disabled');
                    event.params.navigation.prevEl.removeAttribute('disabled');
                }
            }
        }
    };
}

export { getProductSeriesGalleryCarouselConfig };
