import Headroom from 'headroom.js';

(function () {

    'use strict';

    function updateUrlParameter(url, param, value){
        var regex = new RegExp('('+param+'=)[^\&]+');
        return url.replace( regex , '$1' + value);
    }

    function navSearch() {
        let searchBtn = document.querySelector('#searchConfig');
        let pathSearchConfig = searchBtn.dataset.url;
        if (pathSearchConfig.indexOf('html') === -1) pathSearchConfig += '.html';
        let queryParamValue = getQueryParam();
        let queryParam = queryParamValue !== null ? 'q=' + queryParamValue : '';
        let locationSearch = window.location.search === '' ? '?' : window.location.search;
        let pathSearch = '';
        if (locationSearch.includes('q=') && queryParamValue !== null) {
            pathSearch = pathSearchConfig + updateUrlParameter(locationSearch, 'q', queryParamValue);
        } else if (queryParamValue !== null) {
            pathSearch = pathSearchConfig + locationSearch + '&' + queryParam;    
        } else {
            pathSearch = pathSearchConfig + locationSearch;    
        }            
        if (pathSearchConfig !== '' && queryParam !== ''){ 
            window.location.assign(pathSearch);
        }
    }

    function getQueryParam() {
        const searchInput = document.querySelector('input.search-input[type=search]');
        if (searchInput && searchInput.value !== '') {
            return searchInput.value;
        } else {
            return null;
        }
    }

    const boldQuery = (str, query) => {
        const n = str.toUpperCase();
        const q = query.toUpperCase();
        const x = n.indexOf(q);
        if (!q || x === -1) {
            return str;
        }
        const l = q.length;
        return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
    }

    function autocomplete(inp) {
        
        var currentFocus;
        var timer;
        inp.addEventListener("input", function(e) {
            var a, b, i, val = this.value;          
            currentFocus = -1;
            closeAllLists();
            if (!val || (val && val.length < 2)) {
                return false;
            }

            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            this.parentNode.parentNode.appendChild(a);
            
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(async function(){
                let autocompleteResult = await solrAutocomplete();

                for (i = 0; i < autocompleteResult.length; i++) {
                    if (autocompleteResult[i].toUpperCase().includes(val.toUpperCase())) {
                    b = document.createElement("DIV");
                    b.innerHTML = boldQuery(autocompleteResult[i], val);        
                    b.innerHTML += "<input type='hidden' value='" + autocompleteResult[i] + "'>";
                    b.addEventListener("click", function(e) {
                        inp.value = this.getElementsByTagName("input")[0].value;                
                        closeAllLists();
                    });
                    a.appendChild(b);
                    }
                }
            }, 100);            
            
        });

        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) {
                x = x.getElementsByTagName("div");
            } else if (e.keyCode == 13) {
                closeAllLists();
                document.querySelector('#searchConfig').click();
            }
            if (e.keyCode == 40) {
                currentFocus++;
                addActive(x);
            } else if (e.keyCode == 38) {
                currentFocus--;
                addActive(x);
            } else if (e.keyCode == 13) {
                if (currentFocus > -1) {
                    if (x) x[currentFocus].click();                    
                } else {
                    closeAllLists();
                    document.querySelector('#searchConfig').click();
                }                
            }
        });
      
        function addActive(x) {
            if (!x) return false;
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            x[currentFocus].classList.add("autocomplete-active");
        }
      
        function removeActive(x) {
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
      
        function closeAllLists(elmnt) {
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        document.addEventListener("click", function(e) {
            closeAllLists(e.target);
            navSearch();
        });
    }

    async function solrAutocomplete() {
        const searchConfig = document.getElementById('searchConfig');
        if (searchConfig) {
            const searchEndpoint = searchConfig.getAttribute('data-autocomplete-url');
            const offsetParam = '?offset=0';
            const langParam = document.documentElement.lang !== null ? '&language=' + document.documentElement.lang : '';            
            const queryParam = getQueryParam() !== null ? '&title=' + getQueryParam() : '';
            try {
                const requestUrl = searchEndpoint + offsetParam + langParam + queryParam;
                const result = await fetch(requestUrl);
                const responseData = await result.json();

                let autocompleteResult = [];
                if (responseData.results) {                                      
                    [...responseData.results].forEach((card) => {
                        autocompleteResult.push(card.title);
                    });
                }

                return autocompleteResult;

            } catch (error) {
                console.error(error);
            }

        }
    }

    const initNavigation = () => {
        const navBar = document.querySelector('.js-navigation-bar');

        if (navBar) {
            let navBarHeight = navBar.offsetHeight;
            document.documentElement.style.setProperty('--main-navigation-height', `${navBarHeight}px`);

            if (navBar.classList.contains('js-navigation-bar-headroom-enabled')) {
                const headroom = new Headroom(navBar, {
                    offset: 40,
                    tolerance: 0,
                });
                headroom.init();
            }

            // Recalculate navBarHeight on screen resize.
            window.addEventListener('resize', () => {
                navBarHeight = document.querySelector('.js-navigation-bar').offsetHeight;
                document.documentElement.style.setProperty('--main-navigation-height', `${navBarHeight}px`);
            });
            initSearch();
            if (document.getElementById('header-search-input')) {
                autocomplete(document.getElementById('header-search-input'));
            }
            initMenuOverflowHandler();
        }
    };

    if (document.readyState !== 'loading') {
        initNavigation();
    } else {
        document.addEventListener('DOMContentLoaded', initNavigation);
    }

    function initSearch() {

        const openSearchBtn = document.querySelector('.navigation-topmenu__button--search');

        if (openSearchBtn) {
            openSearchBtn.addEventListener('click', () => { 
                document.querySelector('.overlay-search').classList.add('overlay-search--visible');
                document.getElementById('header-search-input').focus();
            });    
        }

        const searchInput = document.querySelector('input.search-input[type=search]');

        function getQueryParam() {
            if (searchInput && searchInput.value !== '') {
                return searchInput.value;
            } else {
                return null;
            }
        }

        let searchBar = document.querySelector('.navigation__search');
        let btnOverlay = document.getElementById('navSearchBtnOverlay'); 
        
        
        if (!openSearchBtn) {return;}
        


        let searchBtn = document.querySelector('#searchConfig');



        searchBtn.addEventListener('click', () => { 
            navSearch();
        })

        let closeBtn = document.querySelector('.close-button');

        closeBtn.addEventListener('click', () => {
            document.querySelector('.overlay-search').classList.remove('overlay-search--visible');
        });

        let clearBtn = document.querySelector('#header-search-clear-button');
        clearBtn.addEventListener('click', () => { 
            document.querySelector('#header-search-input').value = '';
        });  
    }

    function evaluateMenuOverflow() {
        const menu = document.querySelector('.navigation-topmenu__menu');
        const menuMirror = document.querySelector('.navigation-topmenu__mirror .navigation-topmenu__menu')
        const mirroredMenuItems = document.querySelector('.navigation-topmenu__mirror .navigation-topmenu__menu .navigation-topmenu__menu-items');
        if (mirroredMenuItems.clientWidth > menuMirror.clientWidth) {
            menu.closest('.navigation-bar').classList.add('navigation-bar--menu-overflow')
        } else {
            menu.closest('.navigation-bar').classList.remove('navigation-bar--menu-overflow')
        }
    }

    function initMenuOverflowHandler() {
        const menuHtml = document.querySelector('.navigation-topmenu')?.innerHTML;
        const menuMirror = document.querySelector('.navigation-topmenu__mirror');
        if (menuHtml && menuMirror) {
            menuMirror.innerHTML = menuHtml;
            evaluateMenuOverflow();
            window.addEventListener('resize', evaluateMenuOverflow);    
        }
    }

})();
