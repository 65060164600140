import { initDropdowns } from '/src/site/base/dropdown';

(function () {
    'use strict';
    const initDoormat = () => {
        [...document.querySelectorAll('.doormat-block__column')].forEach((doormatBlock) => {
            const titleBlock = doormatBlock.querySelector('.doormat-block__title');
            const contentBlock = doormatBlock.querySelector('.doormat-block__links');

            if (titleBlock) {
                titleBlock.classList.add('doormat-block__title--hide-mobile');

                if (contentBlock) {
                    const dropdownButton = document.createElement('button');
                    dropdownButton.setAttribute('class', 'dropdown__button hidden--from-tablet doormat-block__button--show-mobile js-inserted-tag');
                    dropdownButton.setAttribute('type', 'button');
                    dropdownButton.setAttribute('aria-expanded', 'false');
                    dropdownButton.innerHTML = `
                            <span class="dropdown__button__text">${titleBlock.textContent.trim()}</span>
                        `;
                    titleBlock.insertAdjacentElement('afterend', dropdownButton);

                    contentBlock.classList.add('dropdown__content');
                }
            }

            initDropdowns(doormatBlock);
        });
    };

    if (document.readyState !== 'loading') {
        initDoormat();
    } else {
        document.addEventListener('DOMContentLoaded', initDoormat);
    }

}());
