(function() {

    async function _init() {

        const lcsEmbedComponent = document.querySelector('.js-cmp-lcs-embed');

        if (!lcsEmbedComponent) {
            return;
        }

        const webComponentDefinitionUrl = lcsEmbedComponent.dataset.url;

        import(webComponentDefinitionUrl)
        .catch((error) => {
            console.log(error);
        });

        const redirectUrl = lcsEmbedComponent.dataset.redirect;
        const hasAllParameters = lcsEmbedComponent.hasAttribute('data-has-all-params');
        const editMode = lcsEmbedComponent.hasAttribute('data-edit');

        if (redirectUrl && !hasAllParameters && !editMode) {
            setTimeout(() => {
                location.href = redirectUrl;
            }, 5000);
        }
    
    }

    if (document.readyState !== 'loading') {
        _init();
    } else {
        document.addEventListener('DOMContentLoaded', _init);
    }
    
})();
