import Swiper, { Navigation, A11y } from 'swiper';

function getDefaultCarouselConfig(carousel) {
    const intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('js-completely-visible');
            } else {
                entry.target.classList.remove('js-completely-visible');
            }
        });
    };

    const swiperElement = carousel.querySelector('.js-swiper');

    // Next and previous Buttons are outside the swiper container
    const carouselNextButton = carousel.querySelector('.js-swiper-button-next');
    const carouselPrevButton = carousel.querySelector('.js-swiper-button-prev');

    // Get slides in order to observe later on
    const carouselSlides = swiperElement?.querySelectorAll('.js-swiper-slide');

    // Initiate intersection observer with correct root
    const observerOptions = {
        root: swiperElement,
        rootMargin: '0px',
        threshold: 0.97 // Treshold has been lowered to account for browser zoom see: AEM-4676
    };
    const observer = new IntersectionObserver(intersectionCallback, observerOptions);

    return {
        modules: [Navigation, A11y],
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
            disabledClass: 'carousel__control--disabled',
            nextEl: carouselNextButton,
            prevEl: carouselPrevButton
        },
        a11y: {
            // Labels are set on the swiper instance through data attributes
            prevSlideMessage: swiperElement?.dataset.previouslabel,
            nextSlideMessage: swiperElement?.dataset.nextlabel,
            containerMessage: swiperElement?.dataset.collectionlabel,
            slideLabelMessage: swiperElement?.dataset.contentlabel + ' {{index}} / {{slidesLength}}',
        },
        breakpoints: {
            1195: {
                slidesPerView: 'auto',
                spaceBetween: 24
            }
        },
        on: {
            afterInit: function () {
                carouselSlides.forEach((element) => {
                    observer.observe(element);
                });
            },
        },
    };
}

function initializeCarousel(carousel, config) {
    const swiperElement = carousel.querySelector('.js-swiper');
    if (swiperElement) {
        return new Swiper(swiperElement, config);
    }
}

export { getDefaultCarouselConfig, initializeCarousel };
