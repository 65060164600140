import * as Handlebars from 'handlebars';
import { initializeCarousel } from '/src/site/base/carousel';
import { getProductSeriesGalleryCarouselConfig } from '/src/components/b2c/product-series-header/product-series-gallery-config';
import { initializeDatalayerByContext } from '/src/site/utilities/datalayer-custom';


Handlebars.registerHelper('or', function (a, b) {
    return a || b;
});

(function () {

    'use strict';

    class ProductSeriesComparison extends HTMLElement {

        static get observedAttributes() {
            return ['product-one', 'product-two'];
        }

        attributeChangedCallback(name, oldValue, newValue) {
            console.info(`Product Series Comparison - ${name}'s value has been changed from ${oldValue} to ${newValue}`);
        }

        get productOne() {
            return this.getAttribute('product-one');
        }

        set productOne(productId) {
            this.setAttribute('product-one', productId);
        }

        async fetchProduct(attributeName) {
            const filepath = this.getAttribute(attributeName);
            if (filepath.length) {
                const response = await fetch(filepath);
                return await response.json();
            } else {
                return Promise.resolve('');
            }
        }

        get productTwo() {
            return this.getAttribute('product-two');
        }

        set productTwo(productId) {
            this.setAttribute('product-two', productId);
        }

        async connectedCallback() {
            this.render();
            
        }

        async render() {
            const productsData = await Promise.all([
                await this.fetchProduct('product-one'),
                await this.fetchProduct('product-two'),
            ]);

            const templateOrigin = document.getElementById('js-product-series-comparison-template');
            const templateHandlebars = Handlebars.compile(templateOrigin.innerHTML);
            let template = document.createElement('div');
            template.innerHTML = templateHandlebars(productsData);

            [...template.querySelectorAll('.js-product-series-comparison-ellipsible-target')].forEach(ellipsibleTarget => {
                const ellipsibleTrigger = ellipsibleTarget.querySelector('.js-product-series-comparison-ellipsible-trigger');
                ellipsibleTrigger.addEventListener('click', () => {
                    ellipsibleTarget.classList.toggle('cmp-product-series-comparison__row--ellipsed');
                }, false);
            });

            [...template.querySelectorAll('.js-comparison-images-wrapper')].forEach(comparisonImagesWrapper => {
                const productSeriesId = comparisonImagesWrapper.getAttribute('data-product-series-id');
                const comparisonImagesTemplate = document.querySelector(`.js-product-series-comparison-images-template[data-product-series-id="${productSeriesId}"]`);
                if (comparisonImagesTemplate) {
                    const imagesClone = comparisonImagesTemplate.content.firstElementChild.cloneNode(true);
                    comparisonImagesWrapper.appendChild(imagesClone);
                }
            });

            [...template.querySelectorAll('.js-swatch-image-wrapper')].forEach(swatchImageWrapper => {
                const productSeriesId = swatchImageWrapper.getAttribute('data-product-series-id');
                const variantId = swatchImageWrapper.getAttribute('data-variant-id');
                const swatchImage = document.querySelector(`.js-product-series-swatch-image[data-product-series-id="${productSeriesId}"][data-variant-id="${variantId}"] .cmp-image`);
                if (swatchImage) {
                    const imageClone = swatchImage.cloneNode(true);
                    swatchImageWrapper.appendChild(imageClone);
                }
            });

            [...template.querySelectorAll('.js-product-series-comparison-carousel')].forEach(productGallery => {
                const config = getProductSeriesGalleryCarouselConfig(productGallery);
                initializeCarousel(productGallery, config);
            });

            const renderUpdateEvent = new CustomEvent('productSeriesComparisonRenderUpdate', {
                bubbles: true,
                detail: {
                    'product-one-title': productsData[0].productSeriesContentFragment.details.name,
                    'product-two-title': productsData[1].productSeriesContentFragment.details.name,
                }
            });
            document.dispatchEvent(renderUpdateEvent);

            // Add product names to sticky modal header on scroll.
            const productNamesContainer = template.querySelector('.js-product-series-comparison-heading');
            const modalHeader = document.getElementById('js-product-series-comparison-modal')?.querySelector('.modal__header');
            const modalHeaderHeight = modalHeader.offsetHeight;

            const productNamesOutOfViewObserver = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            const headerProductTitles = modalHeader.querySelector('.js-modal-product-titles');
                            headerProductTitles.classList.remove('animation--fade-in');
                            headerProductTitles.classList.add('hidden');
                            modalHeader.querySelector('.js-modal-print').classList.remove('hidden');
                        } else {
                            const headerProductTitles = modalHeader.querySelector('.js-modal-product-titles');
                            headerProductTitles.classList.add('animation--fade-in');
                            headerProductTitles.classList.remove('hidden');
                            modalHeader.querySelector('.js-modal-print').classList.add('hidden');
                        }
                    });

                }, { rootMargin: `-${modalHeaderHeight}px` }
            );
            productNamesOutOfViewObserver.observe(productNamesContainer);

            this.innerHTML = '';
            this.appendChild(template);
            initializeDatalayerByContext(template);

            jsB2c.buttons.init();
        }
    }

    window.customElements.define('product-series-comparison', ProductSeriesComparison);

})();
