import { getDefaultCarouselConfig, initializeCarousel } from '/src/site/base/carousel';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    function initializePictureSliders() {
        const carousels = document.querySelectorAll('.js-picture-slider');

        [...carousels].forEach(carousel => {
            const config = getDefaultCarouselConfig(carousel);
            config.slidesPerView = 'auto';
            config.spaceBetween = 8;
            config.breakpoints['835'] = {spaceBetween: 24};
            initializeCarousel(carousel, config);
        });

        if (carousels.length) {
            document.addEventListener('productSeriesVariantChange', (event) => {
                // Get VariantID from event.
                const variantId = event.detail.variant;
                // Find PictureSliderTabs with this VariantID
                const pictureSliderTabs = document.querySelectorAll(`.js-picture-slider-tab[data-variant-id="${variantId}"]`);
                [...pictureSliderTabs].forEach(pictureSliderTab => {
                    // Find the parent TabPanel of our PictureSliderTab which has a reference to the associated Tab.
                    const tabPanel = pictureSliderTab.closest('.js-picture-slider-tabpanel');
                    if (tabPanel && tabPanel.hasAttribute('data-picture-slider-tab-id')) {
                        // Find the associated Tab.
                        const associatedTabId = tabPanel.getAttribute('data-picture-slider-tab-id');
                        const associatedTabItem = document.getElementById(associatedTabId);
                        if (associatedTabItem) {
                            // Trigger click event on the Tab.
                            const mouseEvent = new MouseEvent('click', {
                                view: window,
                                bubbles: true,
                                cancelable: true
                            });
                            associatedTabItem.dispatchEvent(mouseEvent);
                        }
                    }
                });
            });
        }
    }

    if (document.readyState !== 'loading') {
        initializePictureSliders();
    } else {
        document.addEventListener('DOMContentLoaded', initializePictureSliders);
    }

    jsB2c.picturesliders = (function() {
        return {
            init: initializePictureSliders,
        };
    })();

})();
