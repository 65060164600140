(function () {
    'use strict';

    function handleMessage(event) {
        const accepted_origin = 'https://go.daikin.eu';
        const task = event?.data['task'];
        if (event?.origin === accepted_origin) {
            if (task === 'scroll_top' || event?.data?.includes('pardotSubmit_')) {
                document.querySelectorAll('.js-modal-wrapper')
                    .forEach((element) => element.scrollTo(0, 0));
            }
        }
    }

    window.addEventListener('message', handleMessage, false);
})();
