(function () {

    'use strict';

    function openEmailContext(title = null, urlToShare = null) {
        if (title && urlToShare) {
            window.location.href = `mailto:?subject=${title}&body=${urlToShare}`;
        } else if (urlToShare) {
            window.location.href = `mailto:?body=${urlToShare}`;
        } else if (title) {
            window.location.href = `mailto:?subject=${title}`;
        } else {
            window.location.href = 'mailto:';
        }
    }

    function initShare() {
        const shareTrigger = document.querySelector('.js-share-trigger');

        if (shareTrigger) {
            shareTrigger.addEventListener('click', async () => {
                const shareData = {};
                if (shareTrigger.hasAttribute('data-share-url')) {
                    shareData.url = shareTrigger.getAttribute('data-share-url');
                }

                if (shareTrigger.hasAttribute('data-share-title')) {
                    shareData.title = shareTrigger.getAttribute('data-share-title');
                }

                if (shareTrigger.hasAttribute('data-share-description')) {
                    shareData.text = shareTrigger.getAttribute('data-share-description');
                }

                try {
                    if (navigator.canShare(shareData)) {
                        await navigator.share(shareData);
                    } else {
                        openEmailContext(shareData.title, shareData.url);
                    }
                } catch (error) {
                    openEmailContext(shareData.title, shareData.url);
                }
            });
        }
    }

    if (document.readyState !== 'loading') {
        initShare();
    } else {
        document.addEventListener('DOMContentLoaded', initShare);
    }

})();
