import { Loader } from '@googlemaps/js-api-loader';

async function buildGoogleMap(element, loaderOptions, mapOptions) {
    const loader = new Loader({
        version: 'weekly',
        ...loaderOptions,
    });
    let mapInstance;

    await loader.load().then(() => {
        mapInstance = new google.maps.Map(element, mapOptions);
    });

    return mapInstance;
}

export { buildGoogleMap };
