(function () {
    'use strict';

    window.jsB2c = window.jsB2c || {};

    function initProductSeriesColorSwatches() {
        const swatchRadioLabels = document.querySelectorAll('.product-series-color-swatch__swatch-label');

        function updateVariantSelector(variant) {
            let originalPathName = window.location.pathname;

            let indexOfPageName = originalPathName.lastIndexOf('/') + 1;
            let originalPageName = originalPathName.substring(indexOfPageName);
            originalPageName = originalPageName.substring(0, originalPageName.indexOf('.'));
            let updatedPathName = originalPathName.substring(0, indexOfPageName) + originalPageName + `.${variant}.html`;

            let urlPath = window.location.origin + updatedPathName + window.location.search;
            history.replaceState(history.state, document.title, urlPath);
        }

        // Update color swatch indicator on hover
        function setColorSwatchLabel(element) {
            const colorSwatch = element.closest('.product-series-color-swatch');
            const colorIndicator = colorSwatch.querySelector('.product-series-color-swatch__indicator');
            if (colorIndicator) {
                colorIndicator.textContent = element.querySelector('.product-series-color-swatch__swatch-label-txt').textContent;
            }
        }

        function resetColorSwatchLabel(element) {
            const colorSwatch = element.closest('.product-series-color-swatch');
            const colorIndicator = colorSwatch.querySelector('.product-series-color-swatch__indicator');
            if (colorIndicator) {
                const activeSwatch = colorSwatch.querySelector('input[checked]');
                colorIndicator.textContent = activeSwatch?.labels[0]?.querySelector('.product-series-color-swatch__swatch-label-txt').textContent;
            }
        }

        if (swatchRadioLabels.length) {
            swatchRadioLabels.forEach((element) => {
                element.addEventListener('click', () => {
                    setColorSwatchLabel(element);
                });
            });

            // Initialise product-series-color-swatch__indicator label
            resetColorSwatchLabel(swatchRadioLabels[0]);
        }

        document.addEventListener('change', (event) => {
            if (event.target.matches('.product-series-color-swatch__swatch-input')) {
                // Update product swatch
                const colorSwatch = event.target.closest('.product-series-color-swatch');

                const colorIndicator = colorSwatch.querySelector('.product-series-color-swatch__indicator');
                if (colorIndicator) {
                    colorIndicator.textContent = event.target.labels[0].querySelector('.product-series-color-swatch__swatch-label-txt').textContent;
                }

                // Dispatch product variant change event
                const colorVariant = document.querySelector('input[name="' + event.target.name + '"]:checked').value;

                // Product Series Variant Change event
                if ('productId' in colorSwatch.dataset) {
                    const productId = colorSwatch.dataset.productId;

                    let customEvent = new CustomEvent('productSeriesVariantChange', {
                        bubbles: true,
                        detail: {
                            'product': productId,
                            'variant': colorVariant
                        }
                    });

                    if (colorVariant) {
                        updateVariantSelector(colorVariant);
                    }

                    document.dispatchEvent(customEvent);
                }

                // Product Series List Variant Change event
                if ('productSeriesId' in colorSwatch.dataset) {
                    const productId = colorSwatch.dataset.productSeriesId;

                    let customEvent = new CustomEvent('productSeriesListVariantChange', {
                        bubbles: true,
                        detail: {
                            'product': productId,
                            'variant': colorVariant
                        }
                    });

                    document.dispatchEvent(customEvent);
                }
            }
        }, false);
    }

    if (document.readyState !== 'loading') {
        initProductSeriesColorSwatches();
    } else {
        document.addEventListener('DOMContentLoaded', initProductSeriesColorSwatches);
    }

    jsB2c.productSeriesColorSwatch = (function() {
        return {
            init: initProductSeriesColorSwatches,
        };
    })();

})();
