function getUserCoordinates(options) {
    if (!navigator.geolocation) {
        console.error('Geolocation is not supported by this browser.');
    }

    const userDenied = () => {
        console.error('User denied geolocation.');
    };

    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options)).catch(
        userDenied
    );
}

async function getCityName(position, apiKey) {
    if (!apiKey || !position) {
        return;
    }

    try {
        const requestUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${position.coords.latitude},${position.coords.longitude}&result_type=locality`;
        const result = await fetch(requestUrl);
        const responseData = await result.json();
        let cityName;

        if (!responseData || !responseData.results || responseData.status !== 'OK') {
            return;
        }

        if (!responseData.results[0].address_components || !responseData.results[0].address_components.length) {
            return;
        }

        cityName = responseData.results[0].address_components[0] && responseData.results[0].address_components[0].long_name;

        return cityName;
    } catch (error) {
        console.error(error);
        return null;
    }
}

async function getDealerCount(position, radius, dealerLocatorSearchEndpoint) {
    try {
        const requestUrl = new URL(dealerLocatorSearchEndpoint, location.origin);
        requestUrl.searchParams.append('rows', '2000');
        requestUrl.searchParams.append('d', radius);
        requestUrl.searchParams.append('pt', position.coords.latitude + ',' + position.coords.longitude);
        const result = await fetch(requestUrl);
        const responseData = await result.json();

        if (!responseData) {
            return;
        }

        return responseData.total;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export { getUserCoordinates, getCityName, getDealerCount };
