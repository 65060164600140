import { Autoplay, A11y, EffectFade, Lazy, Navigation, Pagination } from 'swiper';
import { initializeCarousel } from '/src/site/base/carousel';

function getWatchAndLearnStoryConfig(carousel, slideDuration) {
    // Next and previous Buttons are outside the swiper container.
    const carouselNextButton = carousel.querySelector('.js-swiper-button-next');
    const carouselPrevButton = carousel.querySelector('.js-swiper-button-prev');

    return {
        modules: [Autoplay, Navigation, Pagination, EffectFade, Lazy, A11y],
        speed: 300,
        autoplay: {
            delay: slideDuration,
            disableOnInteraction: false,
            stopOnLastSlide: true
        },
        preloadImages: true,
        pagination: {
            el: '.cmp-watch-and-learn-highlight__pagination',
            type: 'bullets',
            clickable: 'true',
            bulletActiveClass: 'cmp-watch-and-learn-highlight__pagination-indicator--active',
            bulletClass: 'cmp-watch-and-learn-highlight__pagination-indicator',
            hiddenClass: 'hidden',
            horizontalClass: 'cmp-watch-and-learn-highlight__pagination--horizontal',
            lockClass: 'cmp-watch-and-learn-highlight__pagination--locked',
            modifierClass: 'cmp-watch-and-learn-highlight__pagination--',
            paginationDisabledClass: 'disabled'
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        slideClass: 'js-swiper-slide',
        navigation: {
            disabledClass: 'cmp-watch-and-learn-highlight__icon--disabled',
            nextEl: carouselNextButton,
            prevEl: carouselPrevButton,
        },
        a11y: {
            // Labels are set on the swiper instance through data attributes
            prevSlideMessage: 'prev',
            nextSlideMessage: 'next',
            containerMessage: 'slides',
            slideLabelMessage: 'Slide' + ' {{index}} / {{slidesLength}}'
        }, on: {
            autoplayPause: function (event) {
                event.el.classList.add('is-paused');
            },
            autoplayStart: function (event) {
                event.el.classList.remove('is-paused');
            },
            autoplayStop: function (event) {
                event.el.classList.add('is-paused');
            },
            autoplayResume: function (event) {
                event.el.classList.remove('is-paused');
            },
            reachEnd: function (event) {
                event.el.classList.add('is-ended');
            },
            slideChange: function (event) {
                if (!event.isEnd) {
                    event.el.classList.remove('is-ended');
                }
            }
        }
    };
}

function openWatchAndLearnHighlight(watchAndLearnHighlight, swiperInstance, swiperConfig) {
    watchAndLearnHighlight.classList.add('js-slide-show-started');
    watchAndLearnHighlight.setAttribute('tabindex', '-1');
    watchAndLearnHighlight.focus();

    //check if there are more than one slides and initialize swiper
    if (watchAndLearnHighlight.querySelectorAll('.cmp-watch-and-learn-highlight__slide').length > 1) {
        swiperInstance = initializeCarousel(watchAndLearnHighlight, swiperConfig);
    } else {
        // Remove UI controls and don't initialize swiper
        const uiControls= watchAndLearnHighlight.querySelectorAll('.cmp-watch-and-learn-highlight__nav > *:not(.js-close-btn)');
        [...uiControls].forEach(uiControl => {
            uiControl.remove();
        });
    }
    
    return swiperInstance;
}

function closeWatchAndLearnHighlight(watchAndLearnHighlight, swiperInstance, startSlideshowTrigger) {
    watchAndLearnHighlight.classList.remove('js-slide-show-started');
    startSlideshowTrigger.focus();
    if (swiperInstance !== undefined) {
        if (typeof swiperInstance.destroy == 'function') {
            swiperInstance.destroy(false);
        }
    }
}

function setWatchAndLearnSlideDurationCssVariable(slideDurationInMilliseconds) {
    document.documentElement.style.setProperty('--watch-and-learn-slide-duration', `${slideDurationInMilliseconds}ms`);
}

function initializeWatchAndLearnStory(story, modal) {
    const SLIDE_DURATION_IN_MILLISECONDS = 15000;
    const pauseButton = story.querySelector('.js-pause-btn');
    const rewindButton = story.querySelector('.js-rewind-btn');

    setWatchAndLearnSlideDurationCssVariable(SLIDE_DURATION_IN_MILLISECONDS);

    const closeButtons = story.querySelectorAll('.js-close-btn');
    const config = getWatchAndLearnStoryConfig(story, SLIDE_DURATION_IN_MILLISECONDS);
    let swiperInstance;
    let slideShowStartBtn;

    const startSlideShowTriggers = story.querySelectorAll('.js-play-highlight');

    [...startSlideShowTriggers].forEach(startSlideshowTrigger => {
        startSlideshowTrigger.addEventListener('click', () => {
            slideShowStartBtn = startSlideshowTrigger;
            swiperInstance = openWatchAndLearnHighlight(story, swiperInstance, config);
        }, false);
    });

    if (modal) {
        [...closeButtons].forEach(closeBtn => {
            closeBtn.addEventListener('click', () => {
                modal.hide();
            }, false);
        });
    } else {
        [...closeButtons].forEach(closeBtn => {
            closeBtn.addEventListener('click', () => {
                closeWatchAndLearnHighlight(story, swiperInstance, slideShowStartBtn);
            }, false);
        });
    }

    rewindButton.addEventListener('click', () => {
        swiperInstance.slideTo(0);
        swiperInstance.autoplay.start();
    }, false);

    pauseButton.addEventListener('click', () => {
        if (swiperInstance.autoplay.running) {
            swiperInstance.autoplay.stop();
        } else {
            swiperInstance.autoplay.start();
        }
    }, false);
}

function initializeWatchAndLearnStories() {
    const stories = document.querySelectorAll('.js-watch-and-learn-highlight');

    [...stories].forEach(story => {
        initializeWatchAndLearnStory(story);
    });
}

if (document.readyState !== 'loading') {
    initializeWatchAndLearnStories();
} else {
    document.addEventListener('DOMContentLoaded', initializeWatchAndLearnStories);
}

export { initializeWatchAndLearnStory };
