(function () {

    'use strict';
    function _init(){
        if (!document.querySelector('#pardotform')) {
            return;
        }
    
        const pardotForm = document.querySelector('#pardotform');
        const queryStringForPardot = window.location.search;
        if (pardotForm && queryStringForPardot) {
            pardotForm.setAttribute("src", pardotForm.getAttribute('src')+queryStringForPardot);  
        }
    }
   
   
    if (document.readyState !== 'loading') {
        _init();
    } else {
        document.addEventListener('DOMContentLoaded', _init);
    }

})();