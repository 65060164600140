import { getDefaultCarouselConfig, initializeCarousel } from '/src/site/base/carousel';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    function initializeContentCarousels() {
        const carousels = document.querySelectorAll('.js-content-carousel');

        [...carousels].forEach(carousel => {
            const config = getDefaultCarouselConfig(carousel);
            initializeCarousel(carousel, config);
        });
    }

    if (document.readyState !== 'loading') {
        initializeContentCarousels();
    } else {
        document.addEventListener('DOMContentLoaded', initializeContentCarousels);
    }

    jsB2c.contentcarousels = (function() {
        return {
            init: initializeContentCarousels,
        };
    })();

})();
