function openWatchAndLearnVideo(video) {
    video.classList.add('js-video-started');
    video.setAttribute('tabindex', '-1');
    video.focus();
}

function closeWatchAndLearnVideo(video, startVideoTrigger) {
    video.classList.remove('js-video-started');
    startVideoTrigger.focus();
}

function startWatchAndLearnVideo(video) {
    const iframe = video.querySelector('.js-watch-and-learn-video-iframe');

    if (iframe && iframe.hasAttribute('data-src')) {
        const src = iframe.getAttribute('data-src');
        iframe.setAttribute('src', src);
    }
}

function stopWatchAndLearnVideo(video) {
    const iframe = video.querySelector('.js-watch-and-learn-video-iframe');

    if (iframe) {
        iframe.removeAttribute('src');
    }
}

function initializeWatchAndLearnVideo(video, modal) {
    const closeButtons = video.querySelectorAll('.js-close-btn');
    let videoStartBtn;

    const startVideoTriggers = video.querySelectorAll('.js-play-video');

    [...startVideoTriggers].forEach(startVideoTrigger => {
        startVideoTrigger.addEventListener('click', () => {
            videoStartBtn = startVideoTrigger;
            openWatchAndLearnVideo(video);
            startWatchAndLearnVideo(video);
        }, false);
    });

    if (modal) {
        [...closeButtons].forEach(closeBtn => {
            closeBtn.addEventListener('click', () => {
                stopWatchAndLearnVideo(video);
                modal.hide();
            }, false);
        });

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                stopWatchAndLearnVideo(video);
                modal.hide();
            }
        });

    } else {
        [...closeButtons].forEach(closeBtn => {
            closeBtn.addEventListener('click', () => {
                stopWatchAndLearnVideo(video);
                closeWatchAndLearnVideo(video, videoStartBtn);
            }, false);
        });
    }
}

function initializeWatchAndLearnVideos() {
    const videos = document.querySelectorAll('.js-watch-and-learn-video');

    [...videos].forEach(video => {
        initializeWatchAndLearnVideo(video);
    });
}

if (document.readyState !== 'loading') {
    initializeWatchAndLearnVideos();
} else {
    document.addEventListener('DOMContentLoaded', initializeWatchAndLearnVideos);
}

export { initializeWatchAndLearnVideo, startWatchAndLearnVideo };
